<template>
  <v-container
    v-if="!isLoadingTarifaEcon"
    id=""
    grid-list-xs
  >
    <v-row>
      <v-col
        cols="12"
        md="8"
        class="pt-0"
      >
        <v-card
          elevation="0"
          style="border: 1px solid #e3e1e4;"
        >
          <v-container grid-list-xs>
            <v-row>
              <v-col cols="9">
                <div class="d-block">
                  <p class="mb-0 d-flex justify-start">
                    <b>{{
                      aerolineCotizadorDestinosFlight[indAero].destinos[0].flights[
                        aerolineCotizadorDestinosFlight[indAero].destinos[0].indexTarifa
                      ].tarifas_contrate[aerolineCotizadorDestinosFlight[indAero].destinos[0].indTarifa].tarifa
                    }}</b>
                  </p>
                  <p class="mb-0 d-flex justify-start">
                    {{ $t('lbl.clase') }}:
                    <b class="pl-2">{{
                      aerolineCotizadorDestinosFlight[indAero].destinos[0].flights[
                        aerolineCotizadorDestinosFlight[indAero].destinos[0].indexTarifa
                      ].tarifas_contrate[aerolineCotizadorDestinosFlight[indAero].destinos[0].indTarifa].clase
                    }}</b>
                  </p>
                </div>
                <v-row class="pt-4">
                  <v-col cols="1">
                    <v-icon>{{ icons.mdiInformationVariant }}</v-icon>
                  </v-col>
                  <v-col
                    cols="4"
                    class="px-0"
                  >
                    <p
                      caption
                      class="p14Mine mb-0 d-flex justify-center align-baseline"
                    >
                      {{ $t('msg.verifyTarifa') }}
                    </p>
                  </v-col>
                  <v-col cols="7">
                    <div
                      v-if="
                        aerolineCotizadorDestinosFlight[indAero].destinos[0].flights[
                          aerolineCotizadorDestinosFlight[indAero].destinos[0].indexTarifa
                        ].tarifas_contrate[aerolineCotizadorDestinosFlight[indAero].destinos[0].indTarifa]
                          .cant_maletas_bodega !== null
                      "
                      class="d-flex justify-start align-center"
                    >
                      <v-icon color="primary">
                        {{ icons.mdiBagSuitcase }}
                      </v-icon>
                      <p class="p14Mine mb-0 d-flex justify-center align-baseline">
                        {{ $t('lbl.bag') }}:
                        {{
                          $tc(
                            'lbl.pieza',
                            aerolineCotizadorDestinosFlight[indAero].destinos[0].flights[
                              aerolineCotizadorDestinosFlight[indAero].destinos[0].indexTarifa
                            ].tarifas_contrate[aerolineCotizadorDestinosFlight[indAero].destinos[0].indTarifa]
                              .cant_maletas_bodega,
                          )
                        }}
                      </p>
                    </div>
                    <div
                      v-else
                      class="d-flex justify-start align-center"
                    >
                      <v-icon color="default">
                        {{ icons.mdiBagSuitcaseOffOutline }}
                      </v-icon>
                      <p class="p14Mine mb-0 d-flex justify-center align-baseline">
                        {{ $t('lbl.bagNoInclude') }}
                      </p>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="pt-0">
                  <v-col
                    cols="1"
                    class="pt-0"
                  >
                    <v-icon>{{ icons.mdiInformationVariant }}</v-icon>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    class="px-0 pt-0"
                  >
                    <p class="p14Mine mb-0 d-flex justify-center align-baseline">
                      {{ $t('msg.verifyReembolso') }}
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    md="7"
                    class="pt-0"
                  >
                    <div class="d-flex justify-start align-center">
                      <v-icon color="primary">
                        {{ icons.mdiBagPersonal }}
                      </v-icon>
                      <p class="p14Mine mb-0 d-flex justify-center align-baseline">
                        {{ $t('lbl.bagHand') }}:
                        {{
                          `${
                            aerolineCotizadorDestinosFlight[indAero].destinos[0].flights[
                              aerolineCotizadorDestinosFlight[indAero].destinos[0].indexTarifa
                            ].tarifas_contrate[aerolineCotizadorDestinosFlight[indAero].destinos[0].indTarifa]
                              .cant_maletas_cabina > 0
                              ? 'incluido'
                              : 'no incluido'
                          }`
                        }}
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <div class="d-block">
                  <p
                    v-if="showOnlyGestor"
                    class="mb-0 mr-5 pr-6 d-flex justify-center align-center"
                  >
                    {{ priceTotalAdult | currency }}
                  </p>
                  <p
                    v-else
                    class="mb-0 mr-5 pr-6 d-flex justify-center align-center"
                    style="color: #FFFFFF"
                  >
                    .
                  </p>
                  <div class="d-flex justify-center">
                    <p class="mb-0 mr-5 pr-1 d-flex justify-center align-center">
                      <b>{{ priceTotalAdult | currency }}</b>
                    </p>
                    <v-tooltip
                      v-if="showOnlyGestor"
                      top
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ icons.mdiInformation }}
                        </v-icon>
                      </template>
                      <span>
                        <div class="d-block">
                          <div class="d-flex justify-start align-center">
                            <p class="mb-1 mt-2"><b>Detalles del precio</b></p>
                          </div>
                          <div class="d-flex justify-space-between align-center">
                            <p class="mb-0 pr-6">Tarifa</p>
                            <p class="mb-0">776,87 $</p>
                          </div>
                          <div class="d-flex justify-space-between align-center">
                            <p
                              class="mb-0 pr-6"
                              subtitle-2
                            >Impuestos y tasas</p>
                            <p
                              class="mb-0"
                              subtitle-2
                            >776,87 $</p>
                          </div>
                          <v-divider
                            color="white"
                            class="mt-2"
                          ></v-divider>
                          <div class="d-flex justify-space-between align-center">
                            <p class="my-2"><b>Total</b></p>
                            <p
                              class="my-2"
                              subtitle-2
                            >776,87 $</p>
                          </div>
                          <div
                            style="background-color: #ffffff6b"
                            class="mb-4 pa-2"
                          >
                            <div class="d-flex justify-space-between align-center">
                              <p class="mb-0">Tu comisión</p>
                              <p
                                class="mb-0"
                                subtitle-2
                              >776,87 $</p>
                            </div>
                            <div class="d-flex justify-space-between align-center">
                              <p class="mb-0"><b>PVP</b></p>
                              <p
                                class="mb-0"
                                subtitle-2
                              >776,87 $</p>
                            </div>
                          </div>
                        </div>
                      </span>
                    </v-tooltip>
                  </div>
                  <!--<a href="#">
                    <p
                      style="position: absolute; bottom:15px"
                      class="p14Mine mb-0 d-flex justify-center align-center"
                    >
                      {{ $t('lbl.rulesTarifa') }}
                    </p>
                  </a>-->
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <!--BTONES TARIFAS-->
      <v-col
        cols="12"
        md="4"
        class="pt-0"
      >
        <v-btn
          color="primary"
          width="100%"
          class="mb-2"
          @click="setFlight"
        >
          {{ $t('btn.select') }}
        </v-btn>
        <!--<v-btn
          :disabled="aerolineCotizadorDestinosFlight[indAero].flights[indexTarifa].tarifas_contrate.length <= 1"
          color="#e3e1e4"
          width="100%"
          @click="openTarifasContrate(indexTarifa, indAero)"
        >
          {{ $t('lbl.otherTarifa') }}<v-icon>{{ showOtherTarifas ? icons.mdiChevronUp : icons.mdiChevronDown }} </v-icon>
        </v-btn>-->
        <div class="d-flex justify-center align-center">
          <v-container grid-list-xs>
            <v-row>
              <v-col
                cols="2"
                class="pl-0"
              >
                <a
                  style="cursor: pointer"
                  @click="insertPreferidos"
                >
                  <v-icon
                    large
                    :color="
                      idFlightFavorite.includes(aerolineCotizadorDestinosFlight[indAero].id_result) ? 'primary' : ''
                    "
                  >{{
                    idFlightFavorite.includes(aerolineCotizadorDestinosFlight[indAero].id_result)
                      ? icons.mdiHeart
                      : icons.mdiHeartOutline
                  }}</v-icon>
                </a>
              </v-col>
              <v-col
                cols="10"
                class="pr-0"
              >
                <!-- Modal!! -->
                <v-dialog
                  v-model="dialog"
                  max-width="600px"
                  width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="#e3e1e4"
                      width="100%"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ $t('lbl.otherTarifa') }}<v-icon>{{ icons.mdiChevronDown }} </v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-icon
                      large
                      class="mr-5 mt-4"
                      style="position:fixed; top:2rem; right:22rem; z-index:9999"
                      @click="dialog = false"
                    >
                      {{ icons.mdiClose }}
                    </v-icon>
                    <v-container>
                      <v-row>
                        <v-col
                          v-for="(destino, i) in aerolineCotizadorDestinosFlight[indAero].destinos"
                          :key="i"
                          cols="12"
                        >
                          <v-card-text class="mt-4 grey lighten-2 pb-0 text-h6 px-0">
                            {{ destino.origen.city }} ({{ destino.origen.iata }})
                            <v-icon>{{ icons.mdiArrowRight }}</v-icon>
                            {{ destino.destino.city }} ({{ destino.destino.iata }}). {{ $t('lbl.flight') }}
                            {{ destino.flights[destino.indexTarifa].flight.numero_de_vuelo }}
                          </v-card-text>
                          <v-card-text class="mt-0 grey lighten-2 pb-0 text-h6 px-0">
                            {{ destino.from | moment('D MMMM, YYYY') }}
                          </v-card-text>
                          <!-- Card tarifa desktop -->
                          <v-radio-group
                            v-model="destino.indTarifa"
                            mandatory
                          >
                            <v-card
                              v-for="(tarifa, indT) in destino.flights[destino.indexTarifa].tarifas_contrate"
                              :key="indT"
                              style="border:1px solid #e3e1e4"
                              class="my-2"
                            >
                              <v-container grid-list-xs>
                                <v-row>
                                  <v-col cols="6">
                                    <div class="d-block">
                                      <p class="mb-0 d-flex justify-start">
                                        <v-radio
                                          label=""
                                          dense
                                          :value="indT"
                                          @click="setRadio(indT, i)"
                                        ></v-radio>
                                        <b class="pl-2">{{ tarifa.clase }}</b>
                                      </p>
                                      <p class="mb-0 d-flex justify-start">
                                        <b>{{ $t('lbl.tarif') }}: {{ tarifa.tarifa }}</b>
                                      </p>
                                    </div>
                                    <v-row class="pt-4">
                                      <v-col
                                        cols="12"
                                        class="pb-0"
                                      >
                                        <div
                                          v-if="tarifa.cant_maletas_bodega > 0"
                                          class="d-flex justify-start align-center"
                                        >
                                          <v-icon color="primary">
                                            {{ icons.mdiBagSuitcase }}
                                          </v-icon>
                                          <p class="p14Mine mb-0 d-flex justify-center align-baseline">
                                            {{ $t('lbl.bag') }}:
                                            <span class="text-capitalize">
                                              {{ $tc('lbl.pieza', tarifa.cant_maletas_bodega) }}
                                            </span>
                                          </p>
                                        </div>
                                        <div
                                          v-else
                                          class="d-flex justify-start align-center"
                                        >
                                          <v-icon color="default">
                                            {{ icons.mdiBagSuitcaseOffOutline }}
                                          </v-icon>
                                          <p class="p14Mine mb-0 d-flex justify-center align-baseline">
                                            {{ $t('lbl.bag') }}: {{ $t('lbl.bagNot') }}
                                          </p>
                                        </div>
                                      </v-col>
                                      <v-col cols="12">
                                        <div
                                          v-if="tarifa.cant_maletas_cabina > 0"
                                          class="d-flex justify-start align-center"
                                        >
                                          <v-icon color="primary">
                                            {{ icons.mdiBagPersonal }}
                                          </v-icon>
                                          <p class="p14Mine mb-0 d-flex justify-center align-baseline">
                                            {{ $t('lbl.bagHand') }}: incluido
                                          </p>
                                        </div>
                                        <div
                                          v-else
                                          class="d-flex justify-start align-center"
                                        >
                                          <v-icon color="default">
                                            {{ icons.mdiBagPersonalOffOutline }}
                                          </v-icon>
                                          <p class="p14Mine mb-0 d-flex justify-center align-baseline">
                                            {{ $t('lbl.bagHand') }}: no incluido
                                          </p>
                                        </div>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                  <v-col cols="6">
                                    <div class="d-block">
                                      <p
                                        v-if="showOnlyGestor"
                                        class="mb-0 mr-5 pr-12 d-flex justify-end align-center"
                                      >
                                        {{ tarifa.price_total | currency }}
                                      </p>
                                      <p
                                        v-else
                                        class="mb-0 mr-5 pr-12 d-flex justify-end align-center"
                                        style="color: #FFFFFF"
                                      >
                                        .
                                      </p>
                                      <div class="pr-6 d-flex justify-end">
                                        <p class="mb-0 mr-5 d-flex justify-center align-center">
                                          <b>{{ tarifa.price_total | currency }}</b>
                                        </p>
                                        <v-tooltip
                                          v-if="showOnlyGestor"
                                          top
                                        >
                                          <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                              color="primary"
                                              v-bind="attrs"
                                              v-on="on"
                                            >
                                              {{ icons.mdiInformation }}
                                            </v-icon>
                                          </template>
                                          <span>
                                            <div class="d-block">
                                              <div class="d-flex justify-start align-center">
                                                <p class="mb-1 mt-2"><b>Detalles del precio</b></p>
                                              </div>
                                              <div class="d-flex justify-space-between align-center">
                                                <p class="mb-0 pr-6">Tarifa</p>
                                                <p class="mb-0">776,87 $</p>
                                              </div>
                                              <div class="d-flex justify-space-between align-center">
                                                <p
                                                  class="mb-0 pr-6"
                                                  subtitle-2
                                                >Impuestos y tasas</p>
                                                <p
                                                  class="mb-0"
                                                  subtitle-2
                                                >776,87 $</p>
                                              </div>
                                              <v-divider
                                                color="white"
                                                class="mt-2"
                                              ></v-divider>
                                              <div class="d-flex justify-space-between align-center">
                                                <p class="my-2"><b>Total</b></p>
                                                <p
                                                  class="my-2"
                                                  subtitle-2
                                                >776,87 $</p>
                                              </div>
                                              <div
                                                style="background-color: #ffffff6b"
                                                class="mb-4 pa-2"
                                              >
                                                <div class="d-flex justify-space-between align-center">
                                                  <p class="mb-0">Tu comisión</p>
                                                  <p
                                                    class="mb-0"
                                                    subtitle-2
                                                  >776,87 $</p>
                                                </div>
                                                <div class="d-flex justify-space-between align-center">
                                                  <p class="mb-0"><b>PVP</b></p>
                                                  <p
                                                    class="mb-0"
                                                    subtitle-2
                                                  >776,87 $</p>
                                                </div>
                                              </div>
                                            </div>
                                          </span>
                                        </v-tooltip>
                                      </div>
                                      <p
                                        v-if="showOnlyGestor"
                                        class="mb-0 pr-6 p12Mine d-flex justify-end"
                                        style="color:#ce2121"
                                      >
                                        {{ $t('lbl.cupoDisp') }} ({{ tarifa.cupo_disponible }})
                                      </p>
                                    </div>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiCar,
  mdiBed,
  mdiSeatPassenger,
  mdiWalletTravel,
  mdiAirplaneTakeoff,
  mdiAirplane,
  mdiAirplaneLanding,
  mdiSwapHorizontal,
  mdiOfficeBuilding,
  mdiEye,
  mdiPencil,
  mdiDelete,
  mdiPlusThick,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
  mdiChevronDown,
  mdiChevronUp,
  mdiBagPersonal,
  mdiBagSuitcase,
  mdiInformation,
  mdiInformationVariant,
  mdiBagSuitcaseOffOutline,
  mdiHeart,
  mdiHeartOutline,
  mdiArrowRight,
  mdiClose,
  mdiBagPersonalOffOutline,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    indAero: {
      type: Number,
    },
  },
  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoadingTarifaEcon: false,
      showOnlyGestor: true,
      radioGroup: 0,
      items: [],
      showOtherTarifas: false,
      rowShowOtherTarifas: -1,
      dialog: false,
      icons: {
        mdiCar,
        mdiBed,
        mdiSeatPassenger,
        mdiWalletTravel,
        mdiAirplaneTakeoff,
        mdiAirplane,
        mdiAirplaneLanding,
        mdiSwapHorizontal,
        mdiOfficeBuilding,
        mdiEye,
        mdiPencil,
        mdiDelete,
        mdiPlusThick,
        mdiChevronRight,
        mdiPlus,
        mdiMinus,
        mdiChevronDown,
        mdiChevronUp,
        mdiBagPersonal,
        mdiBagSuitcase,
        mdiInformation,
        mdiInformationVariant,
        mdiBagSuitcaseOffOutline,
        mdiHeart,
        mdiHeartOutline,
        mdiArrowRight,
        mdiClose,
        mdiBagPersonalOffOutline,
      },
    }
  },
  computed: {
    ...mapState({
      cotizadorFlight: state => state.app.cotizadorFlight,
      destinosCotizadorFlight: state => state.app.destinosCotizadorFlight,
      resultCotizadorIdaFlight: state => state.app.resultCotizadorIdaFlight,
      tarifaEconCotizadorIdaFlight: state => state.app.tarifaEconCotizadorIdaFlight,
      aerolineCotizadorDestinosFlight: state => state.app.aerolineCotizadorDestinosFlight,
      cotizadorFlightFavorite: state => state.app.cotizadorFlightFavorite,
      idFlightFavorite: state => state.app.idFlightFavorite,
    }),
    priceTotalAdult() {
      let priceTotal = 0
      this.aerolineCotizadorDestinosFlight[this.indAero].destinos.forEach(item => {
        priceTotal += item.flights[item.indexTarifa].tarifas_contrate[item.indTarifa].price_total
      })

      return priceTotal
    },
  },
  created() {
    window.addEventListener('keydown', e => {
      if ((e.key === 'r' || e.key === 'R') && e.shiftKey) {
        this.changePrice()
      }
    })
  },
  methods: {
    ...mapMutations([
      'setTarifaEconCotizadorIdaFligth',
      'insertCotizadorFlightFavorite',
      'deleteCotizadorFlightFavorite',
      'setCotizadorFlightSelect',
      'setIndTarifaEconCotizadorIdaFligth',
      'insertIdFlightFavorite',
      'deleteIdFlightFavorite',
    ]),
    insertPreferidos() {
      const destinos = []
      this.destinosCotizadorFlight.forEach(element => {
        destinos.push({
          origen: element.origen,
          destino: element.destino,
          from: element.from,
          to: element.to,
        })
      })
      const json = {
        cant_adults: this.cotizadorFlight.cant_adults,
        cant_ninnos: this.cotizadorFlight.cant_ninnos,
        cant_infant: this.cotizadorFlight.cant_infant,
        aerolinea: this.cotizadorFlight.aerolinea,
        onlyIda: this.cotizadorFlight.onlyIda,
        destinosMultiples: this.cotizadorFlight.destinosMultiples,
        destinos,
        item_ida: this.aerolineCotizadorDestinosFlight[this.indAero],
        item_regreso: null,
        id_result: this.aerolineCotizadorDestinosFlight[this.indAero].id_result,
      }
      if (!this.idFlightFavorite.includes(this.aerolineCotizadorDestinosFlight[this.indAero].id_result)) {
        // console.log(this.aerolineCotizadorDestinosFlight[this.indAero])
        this.insertCotizadorFlightFavorite(json)
        this.insertIdFlightFavorite(this.aerolineCotizadorDestinosFlight[this.indAero].id_result)
      } else {
        this.deleteCotizadorFlightFavorite(json)
        this.deleteIdFlightFavorite(this.aerolineCotizadorDestinosFlight[this.indAero].id_result)
      }
    },
    setFlight() {
      // console.log(this.aerolineCotizadorDestinosFlight[this.indAero])

      const json = {
        cant_adults: this.cotizadorFlight.cant_adults,
        cant_ninnos: this.cotizadorFlight.cant_ninnos,
        cant_infant: this.cotizadorFlight.cant_infant,
        aerolinea: this.cotizadorFlight.aerolinea,
        onlyIda: this.cotizadorFlight.onlyIda,
        destinosMultiples: this.cotizadorFlight.destinosMultiples,
        aeroline: this.aerolineCotizadorDestinosFlight[this.indAero].destinos[0].flights[
          this.aerolineCotizadorDestinosFlight[this.indAero].destinos[0].indexTarifa
        ].flight.aerolinea,
        destinos: this.aerolineCotizadorDestinosFlight[this.indAero].destinos,
      }
      this.setCotizadorFlightSelect(json)

      this.$router.push({ name: 'selected-cotizador-flights' })
    },
    setRadio(index, i) {
      // i es la pos del destino
      this.setIndTarifaEconCotizadorIdaDestinosFligth({ indAero: this.indAero, indDestino: i, index })
    },
    changePrice() {
      this.showOnlyGestor = !this.showOnlyGestor
    },
  },
}
</script>

<style scoped>
/* Disponibilidad vuelo */
#titleDispVueloMine {
  background-color: #2d3137;
  color: white;
  padding: 10px 12px;
}

#titleDispVueloMine > div > p {
  color: white !important;
  font-size: 14px;
}

#titleDispVueloMine > div > h5 {
  color: white !important;
}

#fromToHeadMine p {
  font-size: 14px;
}

#expandDispMine p {
  font-size: 12px;
  padding: 5px 0px;
}

.v-timeline:before {
  bottom: 0;
  content: '';
  height: 30%;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

.v-timeline:before {
  bottom: 0;
  content: '';
  height: 40% !important;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

.p14Mine {
  font-size: 14px;
}

.p12Mine {
  font-size: 12px;
}
</style>

<template>
  <div
    v-if="!isLoading"
    ref="resultFlight"
  >
    <Filtros />
    <Ida v-if="onlyIda" />
    <IdaBack v-else-if="!multiplesDestinos" />
    <IdaDestinos v-else-if="multiplesDestinos" />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiCar,
  mdiBed,
  mdiSeatPassenger,
  mdiWalletTravel,
  mdiAirplaneTakeoff,
  mdiAirplane,
  mdiAirplaneLanding,
  mdiSwapHorizontal,
  mdiOfficeBuilding,
  mdiEye,
  mdiPencil,
  mdiDelete,
  mdiPlusThick,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
} from '@mdi/js'

import Filtros from './Filtros.vue'
import Ida from './Ida.vue'
import IdaBack from './IdaBack.vue'
import IdaDestinos from './IdaDestinos.vue'

export default {
  components: {
    Filtros,
    Ida,
    IdaBack,
    IdaDestinos,
  },
  data() {
    return {
      itemsOrigen: [],
      isLoading: true,
      loading: false,
      onlyIda: false,
      multiplesDestinos: false,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      icons: {
        mdiCar,
        mdiBed,
        mdiSeatPassenger,
        mdiWalletTravel,
        mdiAirplaneTakeoff,
        mdiAirplane,
        mdiAirplaneLanding,
        mdiSwapHorizontal,
        mdiOfficeBuilding,
        mdiEye,
        mdiPencil,
        mdiDelete,
        mdiPlusThick,
        mdiChevronRight,
        mdiPlus,
        mdiMinus,
      },
    }
  },
  computed: {
    ...mapState({
      cotizadorFlight: state => state.app.cotizadorFlight,
      destinosCotizadorFlight: state => state.app.destinosCotizadorFlight,
    }),
  },
  created() {
    this.getCotizacion()
  },
  methods: {
    ...mapMutations(['deleteDestinoCotizadorFligth']),
    getCotizacion() {
      this.onlyIda = this.cotizadorFlight.onlyIda
      this.multiplesDestinos = this.cotizadorFlight.destinosMultiples

      this.isLoading = false
    },
  },
}
</script>

<style>
/* Disponibilidad vuelo */
#titleDispVueloMine {
  background-color: #2d3137;
  color: white;
  padding: 10px 12px;
}

#titleDispVueloMine>div>p {
  color: white !important;
  font-size: 14px;
}

#titleDispVueloMine>div>h5 {
  color: white !important;
}

#fromToHeadMine p {
  font-size: 14px;
}

#expandDispMine p {
  font-size: 12px;
  padding: 5px 0px;
}

.v-timeline:before {
  bottom: 0;
  content: '';
  height: 25%;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

.p14Mine {
  font-size: 14px;
}

.p12Mine {
  font-size: 12px;
}
</style>

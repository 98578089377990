<template>
  <v-expansion-panel style="border: none;">
    <div
      class="d-flex justify-space-berween align-center px-4"
      style="background-color: aliceblue;"
    >
      <v-row>
        <v-col
          cols="1"
          class="d-flex align-center"
        >
          <v-radio
            class="mb-0"
            :value="index"
            label=""
            @click="setRadioIda(index)"
          ></v-radio>
        </v-col>
        <v-col cols="11">
          <v-row>
            <v-col
              cols="5"
              class="px-1"
            >
              <div
                v-if="aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia.length > 0"
                class="d-block justify-start px-1"
              >
                <p class="mb-0 pr-0 pt-4 pb-0">
                  <b>{{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].origen.salida }}</b> — {{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].destino.llegada }}
                </p>
                <p class="mb-0 pr-0 pb-4 pt-0">
                  {{ $t('lbl.duration') }}
                  {{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].durationDay > 0 ? `${aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].durationDay}d` : '' }}
                  {{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].durationHour > 0 ? `${aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].durationHour}h` : '' }}
                  {{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].durationMin > 0 ? `${aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].durationMin}min.` : '' }}
                </p>
              </div>
            </v-col>
            <v-col
              cols="4"
              class="px-1"
            >
              <div class="d-block justify-start px-1">
                <p class="mb-0 pr-0 pt-4 pb-0">
                  {{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.numero_de_vuelo }}<br />
                  <fragment v-if="aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.escalas !== null">
                    <fragment v-if="aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.escalas.length === 1">
                      {{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.escalas.length }} {{ $t('lbl.escala') }}
                    </fragment>
                    <fragment v-else>
                      {{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.escalas.length }} {{ $t('lbl.escalad') }}
                    </fragment>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ icons.mdiInformation }}
                        </v-icon>
                      </template>
                      <span>
                        <div class="d-block">
                          <div class="d-flex justify-start align-center">
                            <p class="mb-1 mt-2">
                              <b>
                                <fragment
                                  v-for="(esc, ind) in aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.escalas"
                                  :key="ind"
                                >
                                  {{ esc.salidaDuration }},
                                  {{ esc.city.name }}
                                  <br>
                                </fragment>
                              </b>
                            </p>
                          </div>
                        </div>
                      </span>
                    </v-tooltip>
                  </fragment>
                  <fragment v-if="aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.escalas === null">
                    {{ $t('lbl.escala2') }}
                  </fragment>
                </p>
              </div>
            </v-col>
            <v-col
              cols="3"
              class="px-1"
            >
              <div class="d-block justify-start">
                <p class="mb-0 pr-0 pt-4 pb-0">
                  <v-tooltip
                    content-class="custom-tooltip"
                    top
                    color="white"
                    style="background-color: #ffffff"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-row
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-col
                          cols="12"
                          md="2"
                          sm="2"
                        >
                          <v-icon
                            v-if="aerolineCotizadorRegresoFlight[indAero].flights_ida[index].tarifas_contrate[aerolineCotizadorRegresoFlight[indAero].indTarifaIda].cant_maletas_personal > 0"
                            :color="$vuetify.theme.themes.light.primary"
                            small
                            class="mt-2 ml-2"
                          >
                            {{ icons.mdiBagPersonal }}
                          </v-icon>
                          <v-icon
                            v-else
                            :color="$vuetify.theme.themes.light.default"
                            small
                            class="mt-2 ml-2"
                          >
                            {{ icons.mdiBagPersonalOutline }}
                          </v-icon>
                          <!--<Backpack
                            v-if="aerolineCotizadorRegresoFlight[indAero].flights_ida[index].tarifas_contrate[aerolineCotizadorRegresoFlight[indAero].indTarifaIda].cant_maletas_personal > 0"
                            width="20"
                            height="20"
                            :color="$vuetify.theme.themes.light.primary"
                          />
                          <Backpack
                            v-else
                            width="20"
                            height="20"
                            :color="$vuetify.theme.themes.light.default"
                          />-->
                        </v-col>
                        <v-col
                          cols="12"
                          md="2"
                          sm="2"
                        >
                          <CarryOn
                            v-if="aerolineCotizadorRegresoFlight[indAero].flights_ida[index].tarifas_contrate[aerolineCotizadorRegresoFlight[indAero].indTarifaIda].cant_maletas_cabina > 0"
                            width="25"
                            height="25"
                            :color="$vuetify.theme.themes.light.primary"
                          />
                          <CarryOn
                            v-else
                            width="25"
                            height="25"
                            :color="$vuetify.theme.themes.light.default"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="2"
                          sm="2"
                        >
                          <Suitcase
                            v-if="aerolineCotizadorRegresoFlight[indAero].flights_ida[index].tarifas_contrate[aerolineCotizadorRegresoFlight[indAero].indTarifaIda].cant_maletas_bodega > 0"
                            width="25"
                            height="25"
                            :color="$vuetify.theme.themes.light.primary"
                          />
                          <Suitcase
                            v-else
                            width="25"
                            height="25"
                            :color="$vuetify.theme.themes.light.default"
                          />
                        </v-col>
                      </v-row>
                    </template>
                    <span>
                      <div class="d-block rounded-lg rounded-xl">
                        <div
                          class="justify-start align-center rounded-lg rounded-xl"
                        >
                          <v-row
                            style="background-color: #ffffff"
                            no-gutters
                          >
                            <v-col
                              cols="12"
                              md="2"
                              sm="2"
                            >
                              <v-icon
                                v-if="aerolineCotizadorRegresoFlight[indAero].flights_ida[index].tarifas_contrate[aerolineCotizadorRegresoFlight[indAero].indTarifaIda].cant_maletas_personal > 0"
                                :color="$vuetify.theme.themes.light.primary"
                                small
                              >
                                {{ icons.mdiBagPersonal }}
                              </v-icon>
                              <v-icon
                                v-else
                                small
                                :color="$vuetify.theme.themes.light.default"
                              >
                                {{ icons.mdiBagPersonalOutline }}
                              </v-icon>
                            </v-col>
                            <v-col
                              cols="12"
                              md="10"
                              sm="10"
                            >
                              <h3 :style="`color:${$vuetify.theme.themes.light.primary}`">
                                <fragment v-if="aerolineCotizadorRegresoFlight[indAero].flights_ida[index].tarifas_contrate[aerolineCotizadorRegresoFlight[indAero].indTarifaIda].cant_maletas_personal > 0">
                                  {{ $t('msg.bagInclude') }}
                                </fragment>
                                <fragment v-else>
                                  {{ $t('msg.bagNoInclude') }}
                                </fragment>
                              </h3>
                              <p style="color:#666">{{ $t('msg.bagDescription') }}</p>
                            </v-col>

                            <v-col
                              cols="12"
                              md="12"
                              sm="12"
                            >
                              <v-divider
                                class="mb-2"
                                :style="`color:${$vuetify.theme.themes.light.primary}`"
                              ></v-divider>
                            </v-col>
                          </v-row>

                          <v-row
                            style="background-color: #ffffff"
                            no-gutters
                          >
                            <v-col
                              cols="12"
                              md="2"
                              sm="2"
                            >
                              <CarryOn
                                v-if="aerolineCotizadorRegresoFlight[indAero].flights_ida[index].tarifas_contrate[aerolineCotizadorRegresoFlight[indAero].indTarifaIda].cant_maletas_cabina > 0"
                                width="25"
                                height="25"
                                :color="$vuetify.theme.themes.light.primary"
                              />
                              <CarryOn
                                v-else
                                width="25"
                                height="25"
                                :color="$vuetify.theme.themes.light.default"
                              />
                            </v-col>
                            <v-col
                              cols="12"
                              md="10"
                              sm="10"
                            >
                              <h3 :style="`color:${$vuetify.theme.themes.light.primary}`">
                                <fragment v-if="aerolineCotizadorRegresoFlight[indAero].flights_ida[index].tarifas_contrate[aerolineCotizadorRegresoFlight[indAero].indTarifaIda].cant_maletas_cabina > 0">
                                  {{ $t('msg.cabinaInclude') }}
                                </fragment>
                                <fragment v-else>
                                  {{ $t('msg.cabinaNoInclude') }}
                                </fragment>
                              </h3>
                              <p style="color:#666">
                                {{ $t('msg.cabinaDescription') }}
                              </p>
                            </v-col>

                            <v-col
                              cols="12"
                              md="12"
                              sm="12"
                            >
                              <v-divider
                                class="mb-2"
                                :style="`color:${$vuetify.theme.themes.light.primary}`"
                              ></v-divider>
                            </v-col>
                          </v-row>

                          <v-row
                            style="background-color: #ffffff"
                            no-gutters
                          >
                            <v-col
                              cols="12"
                              md="2"
                              sm="2"
                            >
                              <Suitcase
                                v-if="aerolineCotizadorRegresoFlight[indAero].flights_ida[index].tarifas_contrate[aerolineCotizadorRegresoFlight[indAero].indTarifaIda].cant_maletas_bodega > 0"
                                width="25"
                                height="25"
                                :color="$vuetify.theme.themes.light.primary"
                              />
                              <Suitcase
                                v-else
                                width="25"
                                height="25"
                                :color="$vuetify.theme.themes.light.default"
                              />
                            </v-col>
                            <v-col
                              cols="12"
                              md="10"
                              sm="10"
                            >
                              <h3 :style="`color:${$vuetify.theme.themes.light.primary}`">
                                <fragment v-if="aerolineCotizadorRegresoFlight[indAero].flights_ida[index].tarifas_contrate[aerolineCotizadorRegresoFlight[indAero].indTarifaIda].cant_maletas_bodega > 0">
                                  {{ $t('msg.bodegaInclude') }}
                                </fragment>
                                <fragment v-else>
                                  {{ $t('msg.bodegaNoInclude') }}
                                </fragment>
                              </h3>
                              <p style="color:#666">
                                <fragment v-if="aerolineCotizadorRegresoFlight[indAero].flights_ida[index].tarifas_contrate[aerolineCotizadorRegresoFlight[indAero].indTarifaIda].cant_maletas_bodega > 0">
                                  {{ $tc('msg.bags', aerolineCotizadorRegresoFlight[indAero].flights_ida[index].tarifas_contrate[aerolineCotizadorRegresoFlight[indAero].indTarifaIda].cant_maletas_bodega) }}
                                </fragment>
                                <fragment v-else></fragment>
                                <br />
                                {{ $t('msg.bodegaDescription') }}
                              </p>
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                    </span>
                  </v-tooltip>
                </p>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-expansion-panel-header
        ripple
        style="background-color: aliceblue; width:30px"
        class="d-flex justify-center align-center"
      >
      </v-expansion-panel-header>
    </div>
    <v-expansion-panel-content style="background-color: aliceblue;">
      <v-container
        fluid
        class="ma-2"
        style="background-color: white;"
      >
        <v-row
          v-if="aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia.length > 0"
          dense
          class="pt-0"
        >
          <v-col cols="12">
            <v-timeline
              align-top
              dense
              class="pt-0"
            >
              <v-timeline-item
                color="primary"
                small
              >
                <v-row class="pt-1">
                  <v-col cols="4">
                    <strong>{{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].origen.salida }}</strong><br />
                    <span>{{ destinosCotizadorFlight[0].from | moment("D MMMM, YYYY") }}</span>
                  </v-col>
                  <v-col cols="8">
                    <strong class="pr-2">{{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.iata_origen }}</strong><br>
                    <span class="mb-0">
                      {{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.origen.destino.name }},
                      {{ `${aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].origen.terminal !== null ? $t('lbl.terminal'): ''}` }} {{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].origen.terminal }}
                    </span>
                  </v-col>
                  <!--DURACION DEL VUELO-->
                  <v-col cols="12">
                    <span>
                      {{ $t('lbl.duration') }}
                      <span v-if="aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas.length === 0">
                        {{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].duration }}
                      </span>
                      <span v-else>
                        {{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[0].llegadaDuration }}
                      </span>
                    </span><br />
                  </v-col>
                </v-row>
              </v-timeline-item>

              <v-timeline-item
                v-if="aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas.length === 0 ||
                  aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas === null"
                color="primary"
                small
              >
                <v-row class="pt-1">
                  <v-col cols="4">
                    <strong>{{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].destino.llegada }}</strong><br />
                    <span>{{ destinosCotizadorFlight[0].from | moment("add",`${aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].days}  days`,"D MMMM, YYYY") }}</span>
                  </v-col>
                  <v-col cols="8">
                    <strong class="pr-2">{{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.iata_destino }}</strong><br />
                    <span class="mb-0">
                      {{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.destino.destino.name }},
                      {{ `${aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].destino.terminal !== null ? $t('lbl.terminal'): ''}` }} {{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].destino.terminal }}
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    class="pt-2 pl-4 pb-0"
                  >
                    <p class="mb-0 pr-2 py-0">
                      {{ $t('lbl.flight') }} {{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.numero_de_vuelo }}・{{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.aeronave.modelo }}
                    </p>
                  </v-col>
                </v-row>
              </v-timeline-item>
              <!--ESCALA 1-->
              <v-timeline-item
                v-else
                color="primary"
                small
              >
                <v-row class="pt-1">
                  <v-col cols="4">
                    <strong>{{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[0].llegada }}</strong><br />
                    <span>{{ destinosCotizadorFlight[0].from | moment("add",`${aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[0].llegadaDay}  days`,"D MMMM, YYYY") }}</span>
                  </v-col>

                  <v-col cols="8">
                    <strong class="pr-2">{{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[0].city.iata }}</strong><br />
                    <span class="mb-0">
                      {{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[0].city.name }},
                      {{ `${aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[0].llegadaTerminal !== null ? `${$t('lbl.terminal')} ${aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[0].llegadaTerminal}`: ''}` }}
                    </span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    class="pt-4 pb-0"
                  >
                    <p class="mb-0 pr-2 py-0">
                      {{ $t('lbl.flight') }} {{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.numero_de_vuelo }}・{{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.aeronave.modelo }}
                    </p>
                  </v-col>
                </v-row>
              </v-timeline-item>
              <!--DURACION DE LA ESCALA-->
              <v-row
                v-if="aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas.length > 0"
                no-gutters
                class="pb-5"
              >
                <v-col
                  cols="12"
                  md="1"
                  class="text-center pt-5"
                >
                  <v-divider
                    :style="`color:${$vuetify.theme.themes.light.primary}`"
                  ></v-divider>
                </v-col>
                <v-col
                  cols="12"
                  md="10"
                >
                  <v-btn
                    rounded
                    outlined
                    color="primary"
                    block
                    style="text-transform: none;"
                  >
                    <v-icon left>
                      {{ icons.mdiProgressClock }}
                    </v-icon>
                    {{ $t('lbl.escalaIn') }}
                    {{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[0].city.name }}
                    {{ formatDuration(aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[0].salidaDurationM) }}
                  </v-btn>
                </v-col>
                <!--<v-col
                  cols="12"
                  md="1"
                  class="text-center pt-5"
                >
                  <v-divider
                    :style="`color:${$vuetify.theme.themes.light.primary}`"
                  ></v-divider>
                </v-col>-->
              </v-row>
            </v-timeline>

            <!--ESCALA 2-->
            <v-timeline
              v-if="aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas.length > 1"
              align-top
              dense
              class="pt-0"
            >
              <v-timeline-item
                color="primary"
                small
              >
                <v-row class="pt-1">
                  <v-col cols="4">
                    <strong>{{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[0].salida }}</strong><br />
                    <span>{{ destinosCotizadorFlight[0].from | moment("add",
                                                                      `${aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[0].llegadaDay +
                                                                        aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[0].salidaDay +
                                                                        aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[1].llegadaDay}  days`,
                                                                      "D MMMM, YYYY") }}</span>
                  </v-col>
                  <v-col cols="8">
                    <strong class="pr-2">{{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[0].city.iata }}</strong><br>
                    <span class="mb-0">
                      {{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[0].city.name }},
                      {{ `${aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[0].salidaTerminal !== null ? `${$t('lbl.terminal')} ${aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[0].salidaTerminal}`: ''}` }}
                    </span>
                  </v-col>
                  <!--DURACION DEL VUELO-->
                  <v-col cols="12">
                    {{ $t('lbl.duration') }}
                    {{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[1].llegadaDuration }}
                    <br />
                  </v-col>
                </v-row>
              </v-timeline-item>
              <v-timeline-item
                color="primary"
                small
              >
                <v-row class="pt-1">
                  <v-col cols="4">
                    <strong>{{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[1].llegada }}</strong><br />
                    <span>{{ destinosCotizadorFlight[0].from | moment("add",
                                                                      `${aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[0].llegadaDay +
                                                                        aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[0].salidaDay +
                                                                        aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[1].llegadaDay +
                                                                        aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[1].salidaDay}  days`,
                                                                      "D MMMM, YYYY") }}</span>
                  </v-col>

                  <v-col cols="8">
                    <strong class="pr-2">{{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.escalas[1].city.iata }}</strong><br />
                    <span class="mb-0">
                      {{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.escalas[1].city.name }},
                      {{ `${aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[1].llegadaTerminal !== null ? `${$t('lbl.terminal')} ${aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[1].llegadaTerminal}`: ''}` }}
                    </span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    class="pt-4 pb-0"
                  >
                    <p class="mb-0 pr-2 py-0">
                      {{ $t('lbl.flight') }} {{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.numero_de_vuelo }}・{{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.aeronave.modelo }}
                    </p>
                  </v-col>
                </v-row>
              </v-timeline-item>
              <!--DURACION DE LA ESCALA-->
              <v-row
                v-if="aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas.length > 1"
                no-gutters
                class="pb-5"
              >
                <v-col
                  cols="12"
                  md="1"
                  class="text-center pt-5"
                >
                  <v-divider
                    :style="`color:${$vuetify.theme.themes.light.primary}`"
                  ></v-divider>
                </v-col>
                <v-col
                  cols="12"
                  md="10"
                >
                  <v-btn
                    rounded
                    outlined
                    color="primary"
                    block
                    style="text-transform: none;"
                  >
                    <v-icon left>
                      {{ icons.mdiProgressClock }}
                    </v-icon>
                    {{ $t('lbl.escalaIn') }}
                    {{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[1].city.name }}
                    {{ formatDuration(aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[1].salidaDurationM) }}
                  </v-btn>
                </v-col>
                <!--<v-col
                  cols="12"
                  md="2"
                  class="text-center pt-5"
                >
                  <v-divider
                    :style="`color:${$vuetify.theme.themes.light.primary}`"
                  ></v-divider>
                </v-col>-->
              </v-row>
            </v-timeline>

            <!--ESCALA 3-->
            <v-timeline
              v-if="aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas.length > 2"
              align-top
              dense
              class="pt-0"
            >
              <v-timeline-item
                color="primary"
                small
              >
                <v-row class="pt-1">
                  <v-col cols="4">
                    <strong>{{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[1].salida }}</strong><br />
                    <span>{{ destinosCotizadorFlight[0].from | moment("add",
                                                                      `${aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[0].llegadaDay +
                                                                        aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[0].salidaDay +
                                                                        aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[1].llegadaDay +
                                                                        aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[1].salidaDay}  days`,
                                                                      "D MMMM, YYYY") }}</span>
                  </v-col>
                  <v-col cols="8">
                    <strong class="pr-2">{{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[1].city.iata }}</strong><br>
                    <span class="mb-0">
                      {{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[1].city.name }},
                      {{ `${aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[1].salidaTerminal !== null ? `${$t('lbl.terminal')} ${aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[1].salidaTerminal}`: ''}` }}
                    </span>
                  </v-col>
                  <!--DURACION DEL VUELO-->
                  <v-col cols="12">
                    {{ $t('lbl.duration') }}
                    {{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[2].llegadaDuration }}
                    <br />
                  </v-col>
                </v-row>
              </v-timeline-item>
              <v-timeline-item
                color="primary"
                small
              >
                <v-row class="pt-1">
                  <v-col cols="4">
                    <strong>{{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[2].llegada }}</strong><br />
                    <span>{{ destinosCotizadorFlight[0].from | moment("add",
                                                                      `${aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[0].llegadaDay +
                                                                        aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[0].salidaDay +
                                                                        aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[1].llegadaDay +
                                                                        aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[1].salidaDay +
                                                                        aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[2].llegadaDay}  days`,
                                                                      "D MMMM, YYYY") }}</span>
                  </v-col>

                  <v-col cols="8">
                    <strong class="pr-2">{{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.escalas[2].city.iata }}</strong><br />
                    <span class="mb-0">
                      {{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.escalas[2].city.name }},
                      {{ `${aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[2].llegadaTerminal !== null ? `${$t('lbl.terminal')} ${aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[2].llegadaTerminal}`: ''}` }}
                    </span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    class="pt-4 pb-0"
                  >
                    <p class="mb-0 pr-2 py-0">
                      {{ $t('lbl.flight') }} {{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.numero_de_vuelo }}・{{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.aeronave.modelo }}
                    </p>
                  </v-col>
                </v-row>
              </v-timeline-item>
              <!--DURACION DE LA ESCALA-->
              <v-row
                v-if="aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas.length > 1"
                no-gutters
                class="pb-5"
              >
                <v-col
                  cols="12"
                  md="1"
                  class="text-center pt-5"
                >
                  <v-divider
                    :style="`color:${$vuetify.theme.themes.light.primary}`"
                  ></v-divider>
                </v-col>
                <v-col
                  cols="12"
                  md="10"
                >
                  <v-btn
                    rounded
                    outlined
                    color="primary"
                    block
                    style="text-transform: none;"
                  >
                    <v-icon left>
                      {{ icons.mdiProgressClock }}
                    </v-icon>
                    {{ $t('lbl.escalaIn') }}
                    {{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[2].city.name }}
                    {{ formatDuration(aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[2].salidaDurationM) }}
                  </v-btn>
                </v-col>
                <!--<v-col
                  cols="12"
                  md="2"
                  class="text-center pt-5"
                >
                  <v-divider
                    :style="`color:${$vuetify.theme.themes.light.primary}`"
                  ></v-divider>
                </v-col>-->
              </v-row>
            </v-timeline>

            <!--DESTINO-->
            <v-timeline
              v-if="aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas.length > 0"
              align-top
              dense
              class="pt-0"
            >
              <v-timeline-item
                color="primary"
                small
              >
                <v-row class="pt-1">
                  <v-col cols="4">
                    <strong>{{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[
                      aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas.length - 1
                    ].salida }}</strong><br />
                    <span>{{ destinosCotizadorFlight[0].from | moment("add", `${aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].days - aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas.length - 1].salidaDay}  days`,"D MMMM, YYYY") }}</span>
                  </v-col>
                  <v-col cols="8">
                    <strong class="pr-2">
                      {{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas.length - 1].city.iata }}
                    </strong><br>
                    <span class="mb-0">
                      {{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas.length - 1].city.name }},
                      {{ `${aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas.length - 1].salidaTerminal !== null ? `${$t('lbl.terminal')} ${aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas[aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].escalas.length - 1].salidaTerminal}`: ''}` }}
                    </span>
                  </v-col>
                  <!--DURACION DEL VUELO-->
                  <v-col cols="12">
                    {{ $t('lbl.duration') }}
                    {{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].destino.duration }}
                    <br />
                  </v-col>
                </v-row>
              </v-timeline-item>
              <v-timeline-item
                color="primary"
                small
              >
                <v-row class="pt-1">
                  <v-col cols="4">
                    <strong>{{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].destino.llegada }}</strong><br />
                    <span>{{ destinosCotizadorFlight[0].from | moment("add", `${aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].days}  days`, "D MMMM, YYYY") }}</span>
                  </v-col>

                  <v-col cols="8">
                    <strong class="pr-2">{{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].destino.destino.iata }}</strong><br />
                    <span class="mb-0">
                      {{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].destino.destino.name }},
                      {{ `${aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].destino.terminal !== null ? `${$t('lbl.terminal')} ${aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.frecuencia[0].destino.terminal}`: ''}` }}
                    </span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    class="pt-4 pb-0"
                  >
                    <p class="mb-0 pr-2 py-0">
                      {{ $t('lbl.flight') }} {{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.numero_de_vuelo }}・{{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].flight.aeronave.modelo }}
                    </p>
                  </v-col>
                </v-row>
              </v-timeline-item>
            </v-timeline>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="12"
            sm="12"
            class="ml-10"
          >
            <p class="mb-0 ml-0 py-0">
              <v-icon color="primary">
                mdi-airplane-clock
              </v-icon>
              {{ $t('msg.horarioAirpor') }}
            </p>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="12"
            sm="12"
          >
            <v-divider
              :style="`color:${$vuetify.theme.themes.light.primary}`"
            ></v-divider>
          </v-col>

          <v-col
            cols="12"
            md="6"
            sm="6"
          >
            <p class="mb-0 pr-2 py-0">
              <b>{{ $t('lbl.clase') }}</b>: {{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].tarifas_contrate[aerolineCotizadorRegresoFlight[indAero].indTarifaIda].clase }}
              <br />
              <b>{{ $t('lbl.tarifa') }}</b>:
              {{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].tarifas_contrate[aerolineCotizadorRegresoFlight[indAero].indTarifaIda].tarifa }}
              <span style="color: red">
                ({{ aerolineCotizadorRegresoFlight[indAero].flights_ida[index].tarifas_contrate[aerolineCotizadorRegresoFlight[indAero].indTarifaIda].cupo_disponible }}
                {{ $t('lbl.cupo') }})
              </span>
            </p>
          </v-col>
          <!--OTRAS TARIFAS-->
          <v-col
            cols="12"
            md="6"
            sm="6"
          >
            <div class="d-flex justify-center align-center">
              <v-container grid-list-xs>
                <v-row>
                  <v-col
                    cols="10"
                    class="pr-0"
                  >
                    <!-- Modal!! -->
                    <v-dialog
                      v-if="aerolineCotizadorRegresoFlight[indAero].flights_ida[
                        indexTarifaIda
                      ].tarifas_contrate.length >= 2"
                      v-model="dialog"
                      max-width="580px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="#e3e1e4"
                          width="100%"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <span class="text-caption">{{ $t('lbl.otherTarifa') }}</span><v-icon>{{ icons.mdiChevronDown }} </v-icon>
                        </v-btn>
                      </template>
                      <v-card
                        elevation="0"
                        :style="`border: 1px solid ${$vuetify.theme.themes.light.primary}`"
                        class="mb-5"
                      >
                        <v-icon
                          large
                          style="float:right"
                          class="mr-2"
                          @click="dialog = false"
                        >
                          {{ icons.mdiClose }}
                        </v-icon>
                        <v-container>
                          <v-row>
                            <v-col cols="12">
                              <v-card-text class="mt-4 grey lighten-2 pb-0 text-h6 px-0">
                                {{ $t('lbl.ida') }}: {{ destinosCotizadorFlight[0].origen.nombre }} ({{
                                  destinosCotizadorFlight[0].origen.iata
                                }})
                                <v-icon>{{ icons.mdiArrowRight }}</v-icon>
                                {{ destinosCotizadorFlight[0].destino.nombre }}
                                ({{ destinosCotizadorFlight[0].destino.iata }})
                              </v-card-text>
                              <v-card-text class="mt-0 grey lighten-2 pb-0 text-h6 px-0">
                                {{ destinosCotizadorFlight[0].from | moment('MMMM D, YYYY, dddd') }}
                              </v-card-text>
                              <!-- Card tarifa desktop -->
                              <v-radio-group
                                v-model="radioGroupIda"
                                mandatory
                              >
                                <v-card
                                  v-for="(idaTarifa, i) in aerolineCotizadorRegresoFlight[indAero].flights_ida[
                                    indexTarifaIda
                                  ].tarifas_contrate"
                                  :key="i"
                                  style="border:1px solid #e3e1e4"
                                  class="my-2"
                                >
                                  <v-container grid-list-xs>
                                    <v-row>
                                      <v-col cols="6">
                                        <div class="d-block">
                                          <p class="mb-0 d-flex justify-start">
                                            <v-radio
                                              label=""
                                              dense
                                              :value="i"
                                              @click="setRadioTarifaIda(i)"
                                            ></v-radio>
                                            <b class="pl-2">{{ idaTarifa.clase }}</b>
                                          </p>
                                          <p class="mb-0 d-flex justify-start">
                                            <b>{{ $t('lbl.tarif') }}: {{ idaTarifa.tarifa }}</b>
                                          </p>
                                        </div>
                                        <v-row class="pt-4">
                                          <v-col
                                            cols="12"
                                            class="pb-0"
                                          >
                                            <div
                                              v-if="idaTarifa.cant_maletas_bodega > 0"
                                              class="d-flex justify-start align-center"
                                            >
                                              <v-icon color="primary">
                                                {{ icons.mdiBagSuitcase }}
                                              </v-icon>
                                              <p class="p14Mine mb-0 d-flex justify-center align-baseline">
                                                {{ $t('lbl.bag') }}:
                                                <span class="text-capitalize">
                                                  {{ $tc('lbl.pieza', idaTarifa.cant_maletas_bodega) }}
                                                </span>
                                              </p>
                                            </div>
                                            <div
                                              v-else
                                              class="d-flex justify-start align-center"
                                            >
                                              <v-icon color="default">
                                                {{ icons.mdiBagSuitcaseOffOutline }}
                                              </v-icon>
                                              <p class="p14Mine mb-0 d-flex justify-center align-baseline">
                                                {{ $t('lbl.bag') }}: {{ $t('lbl.bagNot') }}
                                              </p>
                                            </div>
                                          </v-col>
                                          <v-col cols="12">
                                            <div
                                              v-if="idaTarifa.cant_maletas_cabina > 0"
                                              class="d-flex justify-start align-center"
                                            >
                                              <v-icon color="primary">
                                                {{ icons.mdiBagPersonal }}
                                              </v-icon>
                                              <p class="p14Mine mb-0 d-flex justify-center align-baseline">
                                                {{ $t('lbl.bagHand') }}: incluido
                                              </p>
                                            </div>
                                            <div
                                              v-else
                                              class="d-flex justify-start align-center"
                                            >
                                              <v-icon color="default">
                                                {{ icons.mdiBagPersonalOffOutline }}
                                              </v-icon>
                                              <p class="p14Mine mb-0 d-flex justify-center align-baseline">
                                                {{ $t('lbl.bagHand') }}: no incluido
                                              </p>
                                            </div>
                                          </v-col>
                                        </v-row>
                                      </v-col>
                                      <v-col cols="6">
                                        <div class="d-block">
                                          <p
                                            v-if="showOnlyGestor"
                                            class="mb-0 mr-5 pr-12 d-flex justify-end align-center"
                                          >
                                            {{ idaTarifa.price_total | currency }}
                                          </p>
                                          <p
                                            v-else
                                            class="mb-0 mr-5 pr-12 d-flex justify-end align-center"
                                            style="color: #FFFFFF"
                                          >
                                            .
                                          </p>
                                          <div class="pr-6 d-flex justify-end">
                                            <p class="mb-0 mr-5 d-flex justify-center align-center">
                                              <b>{{ idaTarifa.price_total | currency }}</b>
                                            </p>
                                            <!--<v-tooltip
                                              v-if="showOnlyGestor"
                                              top
                                            >
                                              <template v-slot:activator="{ on, attrs }">
                                                <v-icon
                                                  color="primary"
                                                  v-bind="attrs"
                                                  v-on="on"
                                                >
                                                  {{ icons.mdiInformation }}
                                                </v-icon>
                                              </template>
                                              <span>
                                                <div class="d-block">
                                                  <div class="d-flex justify-start align-center">
                                                    <p class="mb-1 mt-2"><b>Detalles del precio</b></p>
                                                  </div>
                                                  <div class="d-flex justify-space-between align-center">
                                                    <p class="mb-0 pr-6">Tarifa</p>
                                                    <p class="mb-0">776,87 $</p>
                                                  </div>
                                                  <div class="d-flex justify-space-between align-center">
                                                    <p
                                                      class="mb-0 pr-6"
                                                      subtitle-2
                                                    >Impuestos y tasas</p>
                                                    <p
                                                      class="mb-0"
                                                      subtitle-2
                                                    >776,87 $</p>
                                                  </div>
                                                  <v-divider
                                                    color="white"
                                                    class="mt-2"
                                                  ></v-divider>
                                                  <div class="d-flex justify-space-between align-center">
                                                    <p class="my-2"><b>Total</b></p>
                                                    <p
                                                      class="my-2"
                                                      subtitle-2
                                                    >776,87 $</p>
                                                  </div>
                                                  <div
                                                    style="background-color: #ffffff6b"
                                                    class="mb-4 pa-2"
                                                  >
                                                    <div class="d-flex justify-space-between align-center">
                                                      <p class="mb-0">Tu comisión</p>
                                                      <p
                                                        class="mb-0"
                                                        subtitle-2
                                                      >776,87 $</p>
                                                    </div>
                                                    <div class="d-flex justify-space-between align-center">
                                                      <p class="mb-0"><b>PVP</b></p>
                                                      <p
                                                        class="mb-0"
                                                        subtitle-2
                                                      >776,87 $</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </span>
                                            </v-tooltip>-->
                                          </div>
                                          <p
                                            v-if="showOnlyGestor"
                                            class="mb-0 pr-6 p12Mine d-flex justify-end"
                                            style="color:#ce2121"
                                          >
                                            {{ $t('lbl.cupoDisp') }} ({{ idaTarifa.cupo_disponible }})
                                          </p>
                                        </div>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card>
                              </v-radio-group>
                            </v-col>
                            <!--BACKKK
                            <v-col cols="6">
                              <v-card-text class="mt-4 grey lighten-2 pb-0 text-h6 px-0">
                                {{ $t('lbl.back') }}: {{ destinosCotizadorFlight[0].destino.nombre }} ({{
                                  destinosCotizadorFlight[0].destino.iata
                                }})
                                <v-icon>{{ icons.mdiArrowRight }}</v-icon>
                                {{ destinosCotizadorFlight[0].origen.nombre }}
                                ({{ destinosCotizadorFlight[0].origen.iata }})
                              </v-card-text>
                              <v-card-text class="mt-0 grey lighten-2 pb-0 text-h6 px-0">
                                {{ destinosCotizadorFlight[0].to | moment('MMMM D, YYYY, dddd') }}
                              </v-card-text>
                              <v-radio-group
                                v-model="radioGroupBack"
                                mandatory
                              >
                                <v-card
                                  v-for="(backTarifa, i) in aerolineCotizadorRegresoFlight[indAero].flights_regreso[
                                    indexTarifaRegreso
                                  ].tarifas_contrate"
                                  :key="i"
                                  style="border:1px solid #e3e1e4"
                                  class="my-2"
                                >
                                  <v-container grid-list-xs>
                                    <v-row>
                                      <v-col cols="6">
                                        <div class="d-block">
                                          <p class="mb-0 d-flex justify-start">
                                            <v-radio
                                              label=""
                                              dense
                                              :value="i"
                                              @click="setRadioBack(i)"
                                            ></v-radio>
                                            <b class="pl-2">{{ backTarifa.clase }}</b>
                                          </p>
                                          <p class="mb-0 d-flex justify-start">
                                            <b>{{ $t('lbl.tarif') }}: {{ backTarifa.tarifa }}</b>
                                          </p>
                                        </div>
                                        <v-row class="pt-4">
                                          <v-col
                                            cols="12"
                                            class="pb-0"
                                          >
                                            <div
                                              v-if="backTarifa.cant_maletas_bodega > 0"
                                              class="d-flex justify-start align-center"
                                            >
                                              <v-icon color="primary">
                                                {{ icons.mdiBagSuitcase }}
                                              </v-icon>
                                              <p class="p14Mine mb-0 d-flex justify-center align-baseline">
                                                {{ $t('lbl.bag') }}:
                                                <span class="text-capitalize">
                                                  {{ $tc('lbl.pieza', backTarifa.cant_maletas_bodega) }}
                                                </span>
                                              </p>
                                            </div>
                                            <div
                                              v-else
                                              class="d-flex justify-start align-center"
                                            >
                                              <v-icon color="default">
                                                {{ icons.mdiBagSuitcaseOffOutline }}
                                              </v-icon>
                                              <p class="p14Mine mb-0 d-flex justify-center align-baseline">
                                                {{ $t('lbl.bag') }}: {{ $t('lbl.bagNot') }}
                                              </p>
                                            </div>
                                          </v-col>
                                          <v-col cols="12">
                                            <div
                                              v-if="backTarifa.cant_maletas_cabina > 0"
                                              class="d-flex justify-start align-center"
                                            >
                                              <v-icon color="primary">
                                                {{ icons.mdiBagPersonal }}
                                              </v-icon>
                                              <p class="p14Mine mb-0 d-flex justify-center align-baseline">
                                                {{ $t('lbl.bagHand') }}: incluido
                                              </p>
                                            </div>
                                            <div
                                              v-else
                                              class="d-flex justify-start align-center"
                                            >
                                              <v-icon color="default">
                                                {{ icons.mdiBagPersonalOffOutline }}
                                              </v-icon>
                                              <p class="p14Mine mb-0 d-flex justify-center align-baseline">
                                                {{ $t('lbl.bagHand') }}: no incluido
                                              </p>
                                            </div>
                                          </v-col>
                                        </v-row>
                                      </v-col>
                                      <v-col cols="6">
                                        <div class="d-block">
                                          <p
                                            v-if="showOnlyGestor"
                                            class="mb-0 mr-5 pr-12 d-flex justify-end align-center"
                                          >
                                            <b>{{ backTarifa.price_total | currency }}</b>
                                          </p>
                                          <p
                                            v-else
                                            class="mb-0 mr-5 pr-12 d-flex justify-end align-center"
                                            style="color: #FFFFFF"
                                          >
                                            .
                                          </p>
                                          <div class="pr-6 d-flex justify-end">
                                            <p class="mb-0 mr-5 d-flex justify-center align-center">
                                              <b>{{ backTarifa.price_total | currency }}</b>
                                            </p>
                                            <v-tooltip
                                              v-if="showOnlyGestor"
                                              top
                                            >
                                              <template v-slot:activator="{ on, attrs }">
                                                <v-icon
                                                  color="primary"
                                                  v-bind="attrs"
                                                  v-on="on"
                                                >
                                                  {{ icons.mdiInformation }}
                                                </v-icon>
                                              </template>
                                              <span>
                                                <div class="d-block">
                                                  <div class="d-flex justify-start align-center">
                                                    <p class="mb-1 mt-2"><b>Detalles del precio</b></p>
                                                  </div>
                                                  <div class="d-flex justify-space-between align-center">
                                                    <p class="mb-0 pr-6">Tarifa</p>
                                                    <p class="mb-0">776,87 $</p>
                                                  </div>
                                                  <div class="d-flex justify-space-between align-center">
                                                    <p
                                                      class="mb-0 pr-6"
                                                      subtitle-2
                                                    >Impuestos y tasas</p>
                                                    <p
                                                      class="mb-0"
                                                      subtitle-2
                                                    >776,87 $</p>
                                                  </div>
                                                  <v-divider
                                                    color="white"
                                                    class="mt-2"
                                                  ></v-divider>
                                                  <div class="d-flex justify-space-between align-center">
                                                    <p class="my-2"><b>Total</b></p>
                                                    <p
                                                      class="my-2"
                                                      subtitle-2
                                                    >776,87 $</p>
                                                  </div>
                                                  <div
                                                    style="background-color: #ffffff6b"
                                                    class="mb-4 pa-2"
                                                  >
                                                    <div class="d-flex justify-space-between align-center">
                                                      <p class="mb-0">Tu comisión</p>
                                                      <p
                                                        class="mb-0"
                                                        subtitle-2
                                                      >776,87 $</p>
                                                    </div>
                                                    <div class="d-flex justify-space-between align-center">
                                                      <p class="mb-0"><b>PVP</b></p>
                                                      <p
                                                        class="mb-0"
                                                        subtitle-2
                                                      >776,87 $</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </span>
                                            </v-tooltip>
                                          </div>
                                          <p
                                            v-if="showOnlyGestor"
                                            class="mb-0 pr-6 p12Mine d-flex justify-end"
                                            style="color:#ce2121"
                                          >
                                            {{ $t('lbl.cupoDisp') }} ({{ backTarifa.cupo_disponible }})
                                          </p>
                                        </div>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card>
                              </v-radio-group>
                            </v-col>-->
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-dialog>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-col>

          <v-col
            cols="12"
            md="12"
            sm="12"
          >
            <p>
              <v-icon small>
                {{ icons.mdiCloseThick }}
              </v-icon>
              {{ $t('lbl.noReembolsable') }}<br />

              <span :style="`color:${$vuetify.theme.themes.light.primary}`">
                <v-icon
                  small
                  color="primary"
                >
                  {{ icons.mdiCheckBold }}
                </v-icon>
                {{ $t('lbl.permiteCambios') }}
              </span>
            </p>
            {{ $t('lbl.cambiosView') }}
          </v-col>
        </v-row>
      </v-container>

      <v-container
        style="background-color: white;"
      >
        <v-row>
          <v-col
            cols="12"
            md="3"
            sm="3"
          >
            <b>{{ $t('lbl.equipaje') }}</b>
          </v-col>
          <v-col
            cols="12"
            md="1"
            sm="1"
          >
            <v-divider
              :style="`color:${$vuetify.theme.themes.light.primary}`"
              vertical
            ></v-divider>
          </v-col>
          <v-col
            cols="12"
            md="8"
            sm="8"
          >
            <v-row
              class="mb-0"
              style="background-color: #ffffff"
            >
              <v-col
                cols="12"
                md="1"
                sm="1"
              >
                <v-icon
                  v-if="aerolineCotizadorRegresoFlight[indAero].flights_ida[index].tarifas_contrate[aerolineCotizadorRegresoFlight[indAero].indTarifaIda].cant_maletas_personal > 0"
                  :color="$vuetify.theme.themes.light.primary"
                >
                  {{ icons.mdiBagPersonal }}
                </v-icon>
                <v-icon
                  v-else
                  :color="$vuetify.theme.themes.light.default"
                >
                  {{ icons.mdiBagPersonalOutline }}
                </v-icon>
              </v-col>
              <v-col
                cols="12"
                md="11"
                sm="11"
              >
                <h3 :style="`color:${$vuetify.theme.themes.light.primary}`">
                  <fragment v-if="aerolineCotizadorRegresoFlight[indAero].flights_ida[index].tarifas_contrate[aerolineCotizadorRegresoFlight[indAero].indTarifaIda].cant_maletas_personal > 0">
                    {{ $t('msg.bagInclude') }}
                  </fragment>
                  <fragment v-else>
                    {{ $t('msg.bagNoInclude') }}
                  </fragment>
                </h3>
                <p style="color:#666">
                  {{ $t('msg.bagDescription') }}
                </p>
              </v-col>

              <v-col
                cols="12"
                md="1"
                sm="1"
              >
                <CarryOn
                  v-if="aerolineCotizadorRegresoFlight[indAero].flights_ida[index].tarifas_contrate[aerolineCotizadorRegresoFlight[indAero].indTarifaIda].cant_maletas_cabina > 0"
                  width="25"
                  height="25"
                  :color="$vuetify.theme.themes.light.primary"
                />
                <CarryOn
                  v-else
                  width="25"
                  height="25"
                  :color="$vuetify.theme.themes.light.default"
                />
              </v-col>
              <v-col
                cols="12"
                md="11"
                sm="11"
              >
                <h3 :style="`color:${$vuetify.theme.themes.light.primary}`">
                  <fragment v-if="aerolineCotizadorRegresoFlight[indAero].flights_ida[index].tarifas_contrate[aerolineCotizadorRegresoFlight[indAero].indTarifaIda].cant_maletas_cabina > 0">
                    {{ $t('msg.cabinaInclude') }}
                  </fragment>
                  <fragment v-else>
                    {{ $t('msg.cabinaNoInclude') }}
                  </fragment>
                </h3>
                <p style="color:#666">
                  {{ $t('msg.cabinaDescription') }}
                </p>
              </v-col>

              <v-col
                cols="12"
                md="1"
                sm="1"
              >
                <Suitcase
                  v-if="aerolineCotizadorRegresoFlight[indAero].flights_ida[index].tarifas_contrate[aerolineCotizadorRegresoFlight[indAero].indTarifaIda].cant_maletas_bodega > 0"
                  width="25"
                  height="25"
                  :color="$vuetify.theme.themes.light.primary"
                />
                <Suitcase
                  v-else
                  width="25"
                  height="25"
                  :color="$vuetify.theme.themes.light.default"
                />
              </v-col>
              <v-col
                cols="12"
                md="11"
                sm="11"
              >
                <h3 :style="`color:${$vuetify.theme.themes.light.primary}`">
                  <fragment v-if="aerolineCotizadorRegresoFlight[indAero].flights_ida[index].tarifas_contrate[aerolineCotizadorRegresoFlight[indAero].indTarifaIda].cant_maletas_bodega > 0">
                    {{ $t('msg.bodegaInclude') }}
                  </fragment>
                  <fragment v-else>
                    {{ $t('msg.bodegaNoInclude') }}
                  </fragment>
                </h3>
                <p style="color:#666">
                  {{ $tc('msg.bags', aerolineCotizadorRegresoFlight[indAero].flights_ida[index].tarifas_contrate[aerolineCotizadorRegresoFlight[indAero].indTarifaIda].cant_maletas_bodega) }} <br />
                  {{ $t('msg.bodegaDescription') }}
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiCar,
  mdiBed,
  mdiSeatPassenger,
  mdiWalletTravel,
  mdiAirplaneTakeoff,
  mdiAirplane,
  mdiAirplaneLanding,
  mdiSwapHorizontal,
  mdiOfficeBuilding,
  mdiEye,
  mdiPencil,
  mdiDelete,
  mdiPlusThick,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
  mdiChevronDown,
  mdiInformation,
  mdiBagPersonal,
  mdiBagPersonalOff,
  mdiBagSuitcase,
  mdiBagSuitcaseOff,
  mdiBagCarryOn,
  mdiBagCarryOnOff,
  mdiBagSuitcaseOutline,
  mdiBagSuitcaseOffOutline,
  mdiBagPersonalOutline,
  mdiBagPersonalOffOutline,
  mdiClose,
  mdiCloseThick,
  mdiCheckBold,
  mdiWebClock,
  mdiProgressClock,
} from '@mdi/js'

// eslint-disable-next-line import/no-unresolved
import Suitcase from '@/views/utils/images/suitcase.vue'
// eslint-disable-next-line import/no-unresolved
// import Backpack from '@/views/utils/images/backpack.vue'
// eslint-disable-next-line import/no-unresolved
import CarryOn from '@/views/utils/images/carry_on.vue'

export default {
  components: {
    Suitcase,

    // Backpack,
    CarryOn,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    indAero: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    index: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    indexTarifaIda: {
      type: Number,
    },
  },
  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoadingTarifaEcon: false,
      radioGroup: 0,
      items: [],
      dialog: false,
      radioGroupIda: 0,
      showOnlyGestor: true,
      icons: {
        mdiCar,
        mdiBed,
        mdiSeatPassenger,
        mdiWalletTravel,
        mdiAirplaneTakeoff,
        mdiAirplane,
        mdiAirplaneLanding,
        mdiSwapHorizontal,
        mdiOfficeBuilding,
        mdiEye,
        mdiPencil,
        mdiDelete,
        mdiPlusThick,
        mdiChevronRight,
        mdiPlus,
        mdiMinus,
        mdiChevronDown,
        mdiInformation,
        mdiBagPersonal,
        mdiBagPersonalOff,
        mdiBagSuitcase,
        mdiBagSuitcaseOff,
        mdiBagCarryOn,
        mdiBagCarryOnOff,
        mdiBagSuitcaseOutline,
        mdiBagSuitcaseOffOutline,
        mdiBagPersonalOutline,
        mdiBagPersonalOffOutline,
        mdiClose,
        mdiCloseThick,
        mdiCheckBold,
        mdiWebClock,
        mdiProgressClock,
      },
    }
  },
  computed: {
    ...mapState({
      cotizadorFlight: state => state.app.cotizadorFlight,
      destinosCotizadorFlight: state => state.app.destinosCotizadorFlight,
      aerolineCotizadorRegresoFlight: state => state.app.aerolineCotizadorRegresoFlight,
    }),
  },
  created() {
    window.addEventListener('keydown', e => {
      if ((e.key === 'r' || e.key === 'R') && e.shiftKey) {
        this.changePrice()
      }
    })
  },
  methods: {
    ...mapMutations(['setIndexTarifaIdaEconCotizadorIdaBackFligth', 'setIndTarifaIdaEconCotizadorIdaBackFligth']),
    setRadioIda(index) {
      this.isLoadingTarifaEcon = true

      this.setIndexTarifaIdaEconCotizadorIdaBackFligth({ indAero: this.indAero, index })
    },
    setRadioTarifaIda(index) {
      this.setIndTarifaIdaEconCotizadorIdaBackFligth({ indAero: this.indAero, index })
    },
    changePrice() {
      this.showOnlyGestor = !this.showOnlyGestor
    },

    formatDuration(minutes) {
      let textHours
      let days = 0
      let hours = Math.floor(minutes / 60)
      const mins = minutes % 60
      if (hours > 24) {
        days = Math.floor(hours / 24)
        hours %= 24
        textHours = `${days}d`
      }

      if (days > 0) {
        textHours = `${textHours} ${hours}h ${mins}min.`
      } else {
        textHours = `${hours}h ${mins}min.`
      }

      return textHours
    },
  },
}
</script>

<style scoped>
/* Disponibilidad vuelo */
#titleDispVueloMine {
  background-color: #2d3137;
  color: white;
  padding: 10px 12px;
}

#titleDispVueloMine>div>p {
  color: white !important;
  font-size: 14px;
}

#titleDispVueloMine>div>h5 {
  color: white !important;
}

#fromToHeadMine p {
  font-size: 14px;
}

#expandDispMine p {
  font-size: 12px;
  padding: 5px 0px;
}

.custom-tooltip {
    opacity: 1!important;
}

.v-timeline:before {
  bottom: 0;
  content: '';
  height: 30%;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

.v-timeline:before {
  bottom: 0;
  content: '';
  height: 40% !important;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

.p14Mine {
  font-size: 14px;
}

.p12Mine {
  font-size: 12px;
}
</style>

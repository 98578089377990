<template>
  <svg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="19"
      y="39"
      width="35"
      height="28"
      rx="3"
      :fill="color"
    />
    <path
      d="M45.1134 48.7621V46.1753H20V42.4771C20 40.5567 21.7909 39 24 39H49C51.2091 39 53 40.5567 53 42.4771V66H20V54.4729"
      stroke="#666666"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M27 11.4576C27 3.41148 27 9.9548 27 7.7583C27 5.13036 28.535 3 30.4286 3H41.5714C43.465 3 45 4.79369 45 7.00632C45 12.9152 45 18.0808 45 22.5033C45 29.5624 45 34.728 45 38M27 38C27 37.5553 27 20.9598 27 18.1089V38Z"
      stroke="#666666"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M48 67V68.9754C48 69.5413 47.5523 70 47 70C46.4477 70 46 69.5413 46 68.9754V67"
      stroke="#666666"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M27 67V68.9754C27 69.5413 26.5523 70 26 70C25.4477 70 25 69.5413 25 68.9754V67"
      stroke="#666666"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>

// #666666
export default {
  props: {
    color: {
      type: String,
      default() {
        return ''
      },
    },
  },
}
</script>

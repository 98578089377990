<template>
  <v-expansion-panel style="border: none;">
    <div
      class="d-flex justify-space-between align-center px-4"
      style="background-color: aliceblue;"
    >
      <v-row>
        <v-col
          cols="1"
          class="d-flex align-center"
        >
          <v-radio
            class="mb-0 d-flex justify-center align-right"
            :value="index"
            label=""
            @click="setRadioIda(index)"
          ></v-radio>
        </v-col>
        <v-col cols="3">
          <fragment
            v-if="
              aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia.length > 0
            "
          >
            <p class="mb-0 pr-2 pt-4 pb-0">
              <b>{{
                aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0]
                  .origen.salida
              }}</b>
              —
              {{
                aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0]
                  .destino.llegada
              }}
            </p>
            <p class="mb-0 pr-2 pb-4 pt-0">
              {{ $t('lbl.durationVuelo') }}
              {{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].durationDay > 0 ? `${aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].durationDay}d` : '' }}
              {{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].durationHour > 0 ? `${aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].durationHour}h` : '' }}
              {{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].durationMin > 0 ? `${aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].durationMin}min.` : '' }}
            </p>
          </fragment>
        </v-col>
        <v-col cols="3">
          <div>
            <p class="mb-0 pr-2 pt-4 pb-0">
              <fragment
                v-if="
                  aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.escalas !== null
                "
              >
                <fragment
                  v-if="
                    aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.escalas
                      .length === 1
                  "
                >
                  {{
                    aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.escalas.length
                  }}
                  {{ $t('lbl.escala') }}
                </fragment>
                <fragment v-else>
                  {{
                    aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.escalas.length
                  }}
                  {{ $t('lbl.escalad') }}
                </fragment>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ icons.mdiInformation }}
                    </v-icon>
                  </template>
                  <span>
                    <div class="d-block">
                      <div class="d-flex justify-start align-center">
                        <p class="mb-1 mt-2">
                          <b>
                            <fragment
                              v-for="(esc, ind) in aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.escalas"
                              :key="ind"
                            >
                              {{ esc.salidaDuration }},
                              {{ esc.city.name }}
                              <br>
                            </fragment>
                          </b>
                        </p>
                      </div>
                    </div>
                  </span>
                </v-tooltip>
              </fragment>
              <fragment v-if="aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.escalas === null">
                {{ $t('lbl.escala2') }}
              </fragment>
            </p>
          </div>
        </v-col>
        <v-col cols="2">
          <p class="mb-0 pr-2 pt-4 pb-0">
            {{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.numero_de_vuelo }}
          </p>
        </v-col>
        <v-col cols="3">
          <p class="mb-0 pr-2 pt-4 pb-0">
            {{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.aeronave.modelo }}
          </p>
        </v-col>
      </v-row>
      <v-expansion-panel-header
        ripple
        style="background-color: aliceblue; width:40px"
        class="d-flex justify-center align-center"
      >
      </v-expansion-panel-header>
    </div>
    <v-expansion-panel-content style="background-color: aliceblue;">
      <v-container
        fluid
        class="ma-2"
        style="background-color: white;"
      >
        <v-row
          v-if="
            aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia.length > 0
          "
          dense
          class="pt-0"
        >
          <v-col cols="12">
            <v-timeline
              align-top
              dense
              class="pt-0"
            >
              <v-timeline-item
                color="primary"
                small
              >
                <v-row class="pt-1">
                  <v-col cols="2">
                    <strong>{{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].origen.salida }}</strong><br />
                    <span>{{ destinosCotizadorFlight[0].from | moment("D MMMM, YYYY") }}</span>
                  </v-col>
                  <v-col cols="10">
                    <strong class="pr-2">{{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.iata_origen }}</strong><br>
                    <span class="mb-0">
                      {{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.origen.destino.name }},
                      {{ `${aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].origen.terminal !== null ? `${$t('lbl.terminal')} ${aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].origen.terminal}`: ''}` }}
                    </span>
                  </v-col>
                  <!--DURACION DEL VUELO-->
                  <v-col cols="12">
                    {{ $t('lbl.duration') }}
                    <span v-if="aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas.length === 0">
                      {{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].duration }}
                    </span>
                    <span v-else>
                      {{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[0].llegadaDuration }}
                    </span>
                    <br />
                  </v-col>
                </v-row>
              </v-timeline-item>

              <v-timeline-item
                v-if="aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas.length === 0 ||
                  aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas === null"
                color="primary"
                small
              >
                <v-row class="pt-1">
                  <v-col cols="2">
                    <strong>{{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].destino.llegada }}</strong><br />
                    <span>{{ destinosCotizadorFlight[0].from | moment("add",`${aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].days}  days`,"D MMMM, YYYY") }}</span>
                  </v-col>

                  <v-col cols="10">
                    <strong class="pr-2">{{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.iata_destino }}</strong><br />
                    <span class="mb-0">
                      {{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.destino.destino.name }},
                      {{ `${aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].destino.terminal !== null ? `${$t('lbl.terminal')} ${aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].destino.terminal}`: ''}` }}
                    </span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    class="pt-4 pb-0"
                  >
                    <p class="mb-0 pr-2 py-0">
                      {{ $t('lbl.flight') }} {{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.numero_de_vuelo }}・{{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.aeronave.modelo }}
                    </p>
                  </v-col>
                </v-row>
              </v-timeline-item>
              <!--ESCALA 1-->
              <v-timeline-item
                v-else
                color="primary"
                small
              >
                <v-row class="pt-1">
                  <v-col cols="2">
                    <strong>{{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[0].llegada }}</strong><br />
                    <span>{{ destinosCotizadorFlight[0].from | moment("add",`${aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[0].llegadaDay}  days`,"D MMMM, YYYY") }}</span>
                  </v-col>

                  <v-col cols="10">
                    <strong class="pr-2">{{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[0].city.iata }}</strong><br />
                    <span class="mb-0">
                      {{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[0].city.name }},
                      {{ `${aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[0].llegadaTerminal !== null ? `${$t('lbl.terminal')} ${aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[0].llegadaTerminal}`: ''}` }}
                    </span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    class="pt-4 pb-0"
                  >
                    <p class="mb-0 pr-2 py-0">
                      {{ $t('lbl.flight') }} {{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.numero_de_vuelo }}・{{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.aeronave.modelo }}
                    </p>
                  </v-col>
                </v-row>
              </v-timeline-item>
              <!--DURACION DE LA ESCALA-->
              <v-row
                v-if="aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas.length > 0"
                no-gutters
                class="pb-5"
              >
                <v-col
                  cols="12"
                  md="2"
                  class="text-center pt-5"
                >
                  <v-divider
                    :style="`color:${$vuetify.theme.themes.light.primary}`"
                  ></v-divider>
                </v-col>
                <v-col
                  cols="12"
                  md="8"
                >
                  <v-btn
                    rounded
                    outlined
                    color="primary"
                    block
                    style="text-transform: none;"
                  >
                    <v-icon left>
                      {{ icons.mdiProgressClock }}
                    </v-icon>
                    {{ $t('lbl.escalaIn') }}
                    {{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[0].city.name }}
                    {{ formatDuration(aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[0].salidaDurationM) }}
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                  class="text-center pt-5"
                >
                  <v-divider
                    :style="`color:${$vuetify.theme.themes.light.primary}`"
                  ></v-divider>
                </v-col>
              </v-row>
            </v-timeline>

            <!--ESCALA 2-->
            <v-timeline
              v-if="aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas.length > 1"
              align-top
              dense
              class="pt-0"
            >
              <v-timeline-item
                color="primary"
                small
              >
                <v-row class="pt-1">
                  <v-col cols="2">
                    <strong>{{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[0].salida }}</strong><br />
                    <span>{{ destinosCotizadorFlight[0].from | moment("add",
                                                                      `${aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[0].llegadaDay +
                                                                        aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[0].salidaDay +
                                                                        aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[1].llegadaDay}  days`,
                                                                      "D MMMM, YYYY") }}</span>
                  </v-col>
                  <v-col cols="10">
                    <strong class="pr-2">{{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[0].city.iata }}</strong><br>
                    <span class="mb-0">
                      {{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[0].city.name }},
                      {{ `${aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[0].salidaTerminal !== null ? `${$t('lbl.terminal')} ${aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[0].salidaTerminal}`: ''}` }}
                    </span>
                  </v-col>
                  <!--DURACION DEL VUELO-->
                  <v-col cols="12">
                    {{ $t('lbl.duration') }}
                    {{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[1].llegadaDuration }}
                    <br />
                  </v-col>
                </v-row>
              </v-timeline-item>
              <v-timeline-item
                color="primary"
                small
              >
                <v-row class="pt-1">
                  <v-col cols="2">
                    <strong>{{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[1].llegada }}</strong><br />
                    <span>{{ destinosCotizadorFlight[0].from | moment("add",
                                                                      `${aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[0].llegadaDay +
                                                                        aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[0].salidaDay +
                                                                        aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[1].llegadaDay +
                                                                        aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[1].salidaDay}  days`,
                                                                      "D MMMM, YYYY") }}</span>
                  </v-col>

                  <v-col cols="10">
                    <strong class="pr-2">{{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.escalas[1].city.iata }}</strong><br />
                    <span class="mb-0">
                      {{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.escalas[1].city.name }},
                      {{ `${aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[1].llegadaTerminal !== null ? `${$t('lbl.terminal')} ${aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[1].llegadaTerminal}`: ''}` }}
                    </span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    class="pt-4 pb-0"
                  >
                    <p class="mb-0 pr-2 py-0">
                      {{ $t('lbl.flight') }} {{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.numero_de_vuelo }}・{{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.aeronave.modelo }}
                    </p>
                  </v-col>
                </v-row>
              </v-timeline-item>
              <!--DURACION DE LA ESCALA-->
              <v-row
                v-if="aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas.length > 1"
                no-gutters
                class="pb-5"
              >
                <v-col
                  cols="12"
                  md="2"
                  class="text-center pt-5"
                >
                  <v-divider
                    :style="`color:${$vuetify.theme.themes.light.primary}`"
                  ></v-divider>
                </v-col>
                <v-col
                  cols="12"
                  md="8"
                >
                  <v-btn
                    rounded
                    outlined
                    color="primary"
                    block
                    style="text-transform: none;"
                  >
                    <v-icon left>
                      {{ icons.mdiProgressClock }}
                    </v-icon>
                    {{ $t('lbl.escalaIn') }}
                    {{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[1].city.name }}
                    {{ formatDuration(aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[1].salidaDurationM) }}
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                  class="text-center pt-5"
                >
                  <v-divider
                    :style="`color:${$vuetify.theme.themes.light.primary}`"
                  ></v-divider>
                </v-col>
              </v-row>
            </v-timeline>

            <!--ESCALA 3-->
            <v-timeline
              v-if="aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas.length > 2"
              align-top
              dense
              class="pt-0"
            >
              <v-timeline-item
                color="primary"
                small
              >
                <v-row class="pt-1">
                  <v-col cols="2">
                    <strong>{{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[1].salida }}</strong><br />
                    <span>{{ destinosCotizadorFlight[0].from | moment("add",
                                                                      `${aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[0].llegadaDay +
                                                                        aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[0].salidaDay +
                                                                        aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[1].llegadaDay +
                                                                        aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[1].salidaDay}  days`,
                                                                      "D MMMM, YYYY") }}</span>
                  </v-col>
                  <v-col cols="10">
                    <strong class="pr-2">{{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[1].city.iata }}</strong><br>
                    <span class="mb-0">
                      {{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[1].city.name }},
                      {{ `${aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[1].salidaTerminal !== null ? `${$t('lbl.terminal')} ${aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[1].salidaTerminal}`: ''}` }}
                    </span>
                  </v-col>
                  <!--DURACION DEL VUELO-->
                  <v-col cols="12">
                    {{ $t('lbl.duration') }}
                    {{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[2].llegadaDuration }}
                    <br />
                  </v-col>
                </v-row>
              </v-timeline-item>
              <v-timeline-item
                color="primary"
                small
              >
                <v-row class="pt-1">
                  <v-col cols="2">
                    <strong>{{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[2].llegada }}</strong><br />
                    <span>{{ destinosCotizadorFlight[0].from | moment("add",
                                                                      `${aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[0].llegadaDay +
                                                                        aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[0].salidaDay +
                                                                        aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[1].llegadaDay +
                                                                        aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[1].salidaDay +
                                                                        aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[2].llegadaDay}  days`,
                                                                      "D MMMM, YYYY") }}</span>
                  </v-col>

                  <v-col cols="10">
                    <strong class="pr-2">{{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.escalas[2].city.iata }}</strong><br />
                    <span class="mb-0">
                      {{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.escalas[2].city.name }},
                      {{ `${aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[2].llegadaTerminal !== null ? `${$t('lbl.terminal')} ${aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[2].llegadaTerminal}`: ''}` }}
                    </span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    class="pt-4 pb-0"
                  >
                    <p class="mb-0 pr-2 py-0">
                      {{ $t('lbl.flight') }} {{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.numero_de_vuelo }}・{{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.aeronave.modelo }}
                    </p>
                  </v-col>
                </v-row>
              </v-timeline-item>
              <!--DURACION DE LA ESCALA-->
              <v-row
                v-if="aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas.length > 1"
                no-gutters
                class="pb-5"
              >
                <v-col
                  cols="12"
                  md="2"
                  class="text-center pt-5"
                >
                  <v-divider
                    :style="`color:${$vuetify.theme.themes.light.primary}`"
                  ></v-divider>
                </v-col>
                <v-col
                  cols="12"
                  md="8"
                >
                  <v-btn
                    rounded
                    outlined
                    color="primary"
                    block
                    style="text-transform: none;"
                  >
                    <v-icon left>
                      {{ icons.mdiProgressClock }}
                    </v-icon>
                    {{ $t('lbl.escalaIn') }}
                    {{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[2].city.name }}
                    {{ formatDuration(aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[2].salidaDurationM) }}
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                  class="text-center pt-5"
                >
                  <v-divider
                    :style="`color:${$vuetify.theme.themes.light.primary}`"
                  ></v-divider>
                </v-col>
              </v-row>
            </v-timeline>

            <!--DESTINO-->
            <v-timeline
              v-if="aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas.length > 0"
              align-top
              dense
              class="pt-0"
            >
              <v-timeline-item
                color="primary"
                small
              >
                <v-row class="pt-1">
                  <v-col cols="2">
                    <strong>{{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[1].salida }}</strong><br />
                    <span>{{ destinosCotizadorFlight[0].from | moment("add", `${aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].days - aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas.length - 1].salidaDay}  days`,"D MMMM, YYYY") }}</span>
                  </v-col>
                  <v-col cols="10">
                    <strong class="pr-2">
                      {{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas.length - 1].city.iata }}
                    </strong><br>
                    <span class="mb-0">
                      {{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas.length - 1].city.name }},
                      {{ `${aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas.length - 1].salidaTerminal !== null ? `${$t('lbl.terminal')} ${aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas[aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].escalas.length - 1].salidaTerminal}`: ''}` }}
                    </span>
                  </v-col>
                  <!--DURACION DEL VUELO-->
                  <v-col cols="12">
                    {{ $t('lbl.duration') }}
                    {{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].destino.duration }}
                    <br />
                  </v-col>
                </v-row>
              </v-timeline-item>
              <v-timeline-item
                color="primary"
                small
              >
                <v-row class="pt-1">
                  <v-col cols="2">
                    <strong>{{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].destino.llegada }}</strong><br />
                    <span>{{ destinosCotizadorFlight[0].from | moment("add", `${aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].days}  days`, "D MMMM, YYYY") }}</span>
                  </v-col>

                  <v-col cols="10">
                    <strong class="pr-2">{{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].destino.destino.iata }}</strong><br />
                    <span class="mb-0">
                      {{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].destino.destino.name }},
                      {{ `${aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].destino.terminal !== null ? `${$t('lbl.terminal')} ${aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.frecuencia[0].destino.terminal}`: ''}` }}
                    </span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    class="pt-4 pb-0"
                  >
                    <p class="mb-0 pr-2 py-0">
                      {{ $t('lbl.flight') }} {{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.numero_de_vuelo }}・{{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights[index].flight.aeronave.modelo }}
                    </p>
                  </v-col>
                </v-row>
              </v-timeline-item>
            </v-timeline>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="12"
            sm="12"
            class="ml-10"
          >
            <p class="mb-0 ml-0 py-0">
              <v-icon color="primary">
                mdi-airplane-clock
              </v-icon>
              {{ $t('msg.horarioAirpor') }}
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiCar,
  mdiBed,
  mdiSeatPassenger,
  mdiWalletTravel,
  mdiAirplaneTakeoff,
  mdiAirplane,
  mdiAirplaneLanding,
  mdiSwapHorizontal,
  mdiOfficeBuilding,
  mdiEye,
  mdiPencil,
  mdiDelete,
  mdiPlusThick,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
  mdiChevronDown,
  mdiBagPersonal,
  mdiBagSuitcase,
  mdiInformation,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    indAero: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    indDestino: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    index: {
      type: Number,
    },
  },
  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoadingTarifaEcon: false,
      radioGroup: 0,
      items: [],
      indexTarifa: 0,
      showOtherTarifas: false,
      rowShowOtherTarifas: -1,
      icons: {
        mdiCar,
        mdiBed,
        mdiSeatPassenger,
        mdiWalletTravel,
        mdiAirplaneTakeoff,
        mdiAirplane,
        mdiAirplaneLanding,
        mdiSwapHorizontal,
        mdiOfficeBuilding,
        mdiEye,
        mdiPencil,
        mdiDelete,
        mdiPlusThick,
        mdiChevronRight,
        mdiPlus,
        mdiMinus,
        mdiChevronDown,
        mdiBagPersonal,
        mdiBagSuitcase,
        mdiInformation,
      },
    }
  },
  computed: {
    ...mapState({
      cotizadorFlight: state => state.app.cotizadorFlight,
      destinosCotizadorFlight: state => state.app.destinosCotizadorFlight,
      resultCotizadorIdaFlight: state => state.app.resultCotizadorIdaFlight,
      tarifaEconCotizadorIdaFlight: state => state.app.tarifaEconCotizadorIdaFlight,
      aerolineCotizadorDestinosFlight: state => state.app.aerolineCotizadorDestinosFlight,
    }),
  },
  methods: {
    ...mapMutations(['setTarifaEconCotizadorIdaFligth', 'setIndexTarifaEconCotizadorIdaDestinosFligth', 'setIndTarifaEconCotizadorIdaDestinosFligth']),
    setRadioIda(index) {
      this.isLoadingTarifaEcon = true

      // this.indexTarifa = index

      // const fligtSelect = this.resultCotizadorIdaFlight[this.radioGroup]
      // this.setTarifaEconCotizadorIdaFligth(fligtSelect.tarifas_contrate[0])

      // this.isLoadingTarifaEcon = false
      this.setIndexTarifaEconCotizadorIdaDestinosFligth({ indAero: this.indAero, indDestino: this.indDestino, index })
      this.setIndTarifaEconCotizadorIdaDestinosFligth({ indAero: this.indAero, indDestino: this.indDestino, index: 0 })

      // console.log(this.aerolineCotizadorDestinosFlight[this.indAero].flights[index].tarifas_contrate[0])
    },
    formatDuration(minutes) {
      let textHours
      let days = 0
      let hours = Math.floor(minutes / 60)
      const mins = minutes % 60
      if (hours > 24) {
        days = Math.floor(hours / 24)
        hours %= 24
        textHours = `${days}d`
      }

      if (days > 0) {
        textHours = `${textHours} ${hours}h ${mins}min.`
      } else {
        textHours = `${hours}h ${mins}min.`
      }

      return textHours
    },
  },
}
</script>

<style scoped>
/* Disponibilidad vuelo */
#titleDispVueloMine {
  background-color: #2d3137;
  color: white;
  padding: 10px 12px;
}

#titleDispVueloMine > div > p {
  color: white !important;
  font-size: 14px;
}

#titleDispVueloMine > div > h5 {
  color: white !important;
}

#fromToHeadMine p {
  font-size: 14px;
}

#expandDispMine p {
  font-size: 12px;
  padding: 5px 0px;
}

.v-timeline:before {
  bottom: 0;
  content: '';
  height: 30%;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

.v-timeline:before {
  bottom: 0;
  content: '';
  height: 40% !important;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

.p14Mine {
  font-size: 14px;
}

.p12Mine {
  font-size: 12px;
}
</style>

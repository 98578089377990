import { render, staticRenderFns } from "./IdaAerolineaDestinos.vue?vue&type=template&id=6d3d6b38&"
import script from "./IdaAerolineaDestinos.vue?vue&type=script&lang=js&"
export * from "./IdaAerolineaDestinos.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardTitle,VDivider,VSpacer})

<template>
  <v-card>
    <v-card-title
      id="titleDispVueloMine"
      class="primary"
    >
      <!--<v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <img
            :src="rutaPublic + aerolineCotizadorRegresoFlight[indAero].aeroline.isotipo"
            :alt="aerolineCotizadorRegresoFlight[indAero].aeroline.slug"
            height="25"
            color="white"
            v-bind="attrs"
            v-on="on"
          >
        </template>
        <span>{{ aerolineCotizadorRegresoFlight[indAero].aeroline.name }}</span>
      </v-tooltip>
      <div class="d-flex justify-start align-baseline px-2">
        <p class="mb-0 pr-2">
          {{ $t('lbl.aerolinea') }}:
        </p>
        <h5
          class="mb-0"
          style="font-weight: bold;"
        >
          {{ aerolineCotizadorRegresoFlight[indAero].aeroline.name }}
        </h5>
      </div>-->
      <v-spacer></v-spacer>
      <div class="d-flex justify-start align-baseline px-2">
        <p class="mb-0 pr-2">
          Ticket Time Limit:
        </p>
        <h5 class="mb-0">
          50 minutos
        </h5>
      </div>
    </v-card-title>
    <v-container
      id="fromToHeadMine"
      grid-list-xs
    >
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <div class="d-flex justify-space-between align-center">
            <div class="d-block justify-center">
              <p class="mb-0">
                <b style="font-size: 24px;">{{ $t('lbl.ida') }} . </b>{{ destinosCotizadorFlight[0].from | moment("MMMM D, YYYY, dddd") }}
              </p>
              <div>
                <p class="mb-0 ">
                  {{ destinosCotizadorFlight[0].origen.nombre }}
                  <b>{{ destinosCotizadorFlight[0].origen.iata }}</b>
                  <b>→</b>
                  {{ destinosCotizadorFlight[0].destino.nombre }}
                  <b>{{ destinosCotizadorFlight[0].destino.iata }}</b>
                </p>
              </div>
            </div>
            <div>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <img
                    :src="rutaPublic + aerolineCotizadorRegresoFlight[indAero].flights_ida[0].flight.aerolinea.isotipo"
                    :alt="aerolineCotizadorRegresoFlight[indAero].flights_ida[0].flight.aerolinea.slug"
                    height="40"
                    color="white"
                    v-bind="attrs"
                    v-on="on"
                  >
                </template>
                <span>{{ aerolineCotizadorRegresoFlight[indAero].flights_ida[0].flight.aerolinea.name }}</span>
              </v-tooltip>
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <div class="d-flex justify-space-between align-center">
            <div class="d-block justify-center">
              <p class="mb-0">
                <b style="font-size: 24px;">{{ $t('lbl.back') }} . </b>{{ destinosCotizadorFlight[0].to | moment("MMMM D, YYYY, dddd") }}
              </p>
              <div>
                <p class="mb-0 ">
                  {{ destinosCotizadorFlight[0].destino.nombre }}
                  <b>{{ destinosCotizadorFlight[0].destino.iata }}</b>
                  <b>→</b>
                  {{ destinosCotizadorFlight[0].origen.nombre }}
                  <b>{{ destinosCotizadorFlight[0].origen.iata }}</b>
                </p>
              </div>
            </div>
            <div>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <img
                    :src="rutaPublic + aerolineCotizadorRegresoFlight[indAero].flights_regreso[0].flight.aerolinea.isotipo"
                    :alt="aerolineCotizadorRegresoFlight[indAero].flights_regreso[0].flight.aerolinea.slug"
                    height="40"
                    color="white"
                    v-bind="attrs"
                    v-on="on"
                  >
                </template>
                <span>{{ aerolineCotizadorRegresoFlight[indAero].flights_regreso[0].flight.aerolinea.name }}</span>
              </v-tooltip>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container
      id="expandDispMine"
      grid-list-xs
    >
      <v-row>
        <v-col
          cols="12"
          md="6"
          class="pt-0"
        >
          <v-radio-group
            v-model="radioGroup"
            class="mt-0 pt-0"
            mandatory
          >
            <v-expansion-panels
              accordion
              multiple
            >
              <IdaBackFlightIda
                v-for="(item, index) in aerolineCotizadorRegresoFlight[indAero].flights_ida"
                :key="index"
                :index="index"
                :ind-aero="indAero"
                :index-tarifa-ida="aerolineCotizadorRegresoFlight[indAero].indexTarifaIda"
              />
            </v-expansion-panels>
          </v-radio-group>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="pt-0"
        >
          <v-radio-group
            v-model="radioGroupBack"
            class="mt-0 pt-0"
            mandatory
          >
            <v-expansion-panels
              accordion
              multiple
            >
              <IdaBackFlightBack
                v-for="(item, index) in aerolineCotizadorRegresoFlight[indAero].flights_regreso"
                :key="index"
                :index="index"
                :ind-aero="indAero"
                :index-tarifa-regreso="aerolineCotizadorRegresoFlight[indAero].indexTarifaRegreso"
              />
            </v-expansion-panels>
          </v-radio-group>
        </v-col>
      </v-row>
    </v-container>
    <v-divider class="px-0 py-3"></v-divider>
    <!--TARIFA ECONOMICA-->
    <IdaBackTarifaEconomica
      :ind-aero="indAero"
      :index-tarifa-ida="aerolineCotizadorRegresoFlight[indAero].indexTarifaIda"
      :index-tarifa-regreso="aerolineCotizadorRegresoFlight[indAero].indexTarifaRegreso"
    />
  </v-card>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiCar,
  mdiBed,
  mdiSeatPassenger,
  mdiWalletTravel,
  mdiAirplaneTakeoff,
  mdiAirplane,
  mdiAirplaneLanding,
  mdiSwapHorizontal,
  mdiOfficeBuilding,
  mdiEye,
  mdiPencil,
  mdiDelete,
  mdiPlusThick,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
  mdiChevronDown,
  mdiBagPersonal,
  mdiBagSuitcase,
  mdiInformation,
} from '@mdi/js'

// eslint-disable-next-line import/no-unresolved
import IdaBackFlightIda from './IdaBackFlightIda.vue'
// eslint-disable-next-line import/no-unresolved
import IdaBackFlightBack from './IdaBackFlightBack.vue'
// eslint-disable-next-line import/no-unresolved
import IdaBackTarifaEconomica from './IdaBackTarifaEconomica.vue'

export default {
  components: {
    IdaBackFlightIda,
    IdaBackFlightBack,
    IdaBackTarifaEconomica,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    indAero: {
      type: Number,
    },
  },
  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoadingTarifaEcon: false,
      dialog2: false,
      aerolinea: [],
      aerolin: null,
      radioGroup: 0,
      radioGroupBack: 0,
      items: [],
      indexTarifa: 0,
      showOtherTarifas: false,
      rowShowOtherTarifas: -1,
      icons: {
        mdiCar,
        mdiBed,
        mdiSeatPassenger,
        mdiWalletTravel,
        mdiAirplaneTakeoff,
        mdiAirplane,
        mdiAirplaneLanding,
        mdiSwapHorizontal,
        mdiOfficeBuilding,
        mdiEye,
        mdiPencil,
        mdiDelete,
        mdiPlusThick,
        mdiChevronRight,
        mdiPlus,
        mdiMinus,
        mdiChevronDown,
        mdiBagPersonal,
        mdiBagSuitcase,
        mdiInformation,
      },
    }
  },
  computed: {
    ...mapState({
      cotizadorFlight: state => state.app.cotizadorFlight,
      destinosCotizadorFlight: state => state.app.destinosCotizadorFlight,
      aerolineCotizadorRegresoFlight: state => state.app.aerolineCotizadorRegresoFlight,
    }),
  },
  methods: {
    ...mapMutations(['setTarifaEconCotizadorIdaFligth']),
  },
}
</script>

<template>
  <v-expansion-panel style="border: none;">
    <div
      class="d-flex justify-space-between align-center px-4"
      style="background-color: aliceblue;"
    >
      <v-row>
        <v-col
          cols="1"
          class="d-flex align-center"
        >
          <v-radio
            class="mb-0 d-flex justify-center align-right"
            :value="index"
            label=""
            @click="setRadioIda(index)"
          ></v-radio>
        </v-col>
        <v-col cols="3">
          <fragment v-if="aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia.length > 0">
            <p class="mb-0 pr-2 pt-4 pb-0">
              <b>{{ aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].origen.salida }}</b> —
              {{ aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].destino.llegada }}
            </p>
            <!--<p class="mb-0 pr-2 pb-4 pt-0">
              {{ $t('lbl.durationVuelo') }} {{ aerolineCotizadorIdaFlight[indAero].flights[index].flight.tiempo_total_viaje_h }} {{ `${aerolineCotizadorIdaFlight[indAero].flights[index].flight.tiempo_total_viaje_h !== 0 ? 'h' : ''}` }}
              {{ `${aerolineCotizadorIdaFlight[indAero].flights[index].flight.tiempo_total_viaje_m > 0 ? aerolineCotizadorIdaFlight[indAero].flights[index].flight.tiempo_total_viaje_m : ''}` }} {{ `${aerolineCotizadorIdaFlight[indAero].flights[index].flight.tiempo_total_viaje_m > 0 ? 'min.' : ''}` }}
            </p>-->
            <p class="mb-0 pr-2 pb-4 pt-0">
              {{ $t('lbl.durationVuelo') }}
              {{
                aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].durationDay > 0
                  ? `${aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].durationDay}d`
                  : ''
              }}
              {{
                aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].durationHour > 0
                  ? `${aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].durationHour}h`
                  : ''
              }}
              {{
                aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].durationMin > 0
                  ? `${aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].durationMin}min.`
                  : ''
              }}
            </p>
          </fragment>
        </v-col>
        <v-col cols="2">
          <div>
            <p class="mb-0 pr-2 pt-4 pb-0">
              {{ aerolineCotizadorIdaFlight[indAero].flights[index].flight.numero_de_vuelo }}<br />
              <fragment v-if="aerolineCotizadorIdaFlight[indAero].flights[index].flight.escalas !== null">
                <fragment v-if="aerolineCotizadorIdaFlight[indAero].flights[index].flight.escalas.length === 1">
                  {{ aerolineCotizadorIdaFlight[indAero].flights[index].flight.escalas.length }} {{ $t('lbl.escala') }}
                </fragment>
                <fragment v-else>
                  {{ aerolineCotizadorIdaFlight[indAero].flights[index].flight.escalas.length }} {{ $t('lbl.escalad') }}
                </fragment>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ icons.mdiInformation }}
                    </v-icon>
                  </template>
                  <span>
                    <div class="d-block">
                      <div class="d-flex justify-start align-center">
                        <p class="mb-1 mt-2">
                          <b>
                            <fragment
                              v-for="(esc, ind) in aerolineCotizadorIdaFlight[indAero].flights[index].flight.escalas"
                              :key="ind"
                            >
                              <!--{{ (esc.duration_h !== null && esc.duration_h > 0) ? `${esc.duration_h} h` : '' }}{{ esc.duration_m > 0 ? '' : ',' }}
                              {{ esc.duration_m > 0 ? `${esc.duration_m} min.,` : '' }}-->
                              {{ esc.salidaDuration }},
                              {{ esc.city.name }}
                              <br />
                            </fragment>
                          </b>
                        </p>
                      </div>
                    </div>
                  </span>
                </v-tooltip>
              </fragment>
              <fragment v-if="aerolineCotizadorIdaFlight[indAero].flights[index].flight.escalas === null">
                {{ $t('lbl.escala2') }}
              </fragment>
            </p>
          </div>
        </v-col>
        <v-col
          cols="2"
          class="px-1"
        >
          <div class="d-block justify-start">
            <p class="mb-0 pr-0 pt-4 pb-0">
              <v-tooltip
                content-class="custom-tooltip"
                top
                color="white"
                style="background-color: #ffffff"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-row
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-col
                      cols="12"
                      md="2"
                      sm="2"
                    >
                      <v-icon
                        v-if="
                          aerolineCotizadorIdaFlight[indAero].flights[index].tarifas_contrate[
                            aerolineCotizadorIdaFlight[indAero].indTarifa
                          ].cant_maletas_personal > 0
                        "
                        :color="$vuetify.theme.themes.light.primary"
                        small
                        class="mt-2 ml-2"
                      >
                        {{ icons.mdiBagPersonal }}
                      </v-icon>
                      <v-icon
                        v-else
                        :color="$vuetify.theme.themes.light.default"
                        small
                        class="mt-2 ml-2"
                      >
                        {{ icons.mdiBagPersonalOutline }}
                      </v-icon>
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                      sm="2"
                    >
                      <CarryOn
                        v-if="
                          aerolineCotizadorIdaFlight[indAero].flights[index].tarifas_contrate[
                            aerolineCotizadorIdaFlight[indAero].indTarifa
                          ].cant_maletas_cabina > 0
                        "
                        width="25"
                        height="25"
                        :color="$vuetify.theme.themes.light.primary"
                      />
                      <CarryOn
                        v-else
                        width="25"
                        height="25"
                        :color="$vuetify.theme.themes.light.default"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                      sm="2"
                    >
                      <Suitcase
                        v-if="
                          aerolineCotizadorIdaFlight[indAero].flights[index].tarifas_contrate[
                            aerolineCotizadorIdaFlight[indAero].indTarifa
                          ].cant_maletas_bodega > 0
                        "
                        width="25"
                        height="25"
                        :color="$vuetify.theme.themes.light.primary"
                      />
                      <Suitcase
                        v-else
                        width="25"
                        height="25"
                        :color="$vuetify.theme.themes.light.default"
                      />
                    </v-col>
                  </v-row>
                </template>
                <span>
                  <div class="d-block rounded-lg rounded-xl">
                    <div class="justify-start align-center rounded-lg rounded-xl">
                      <v-row
                        style="background-color: #ffffff"
                        no-gutters
                      >
                        <v-col
                          cols="12"
                          md="2"
                          sm="2"
                        >
                          <v-icon
                            v-if="
                              aerolineCotizadorIdaFlight[indAero].flights[index].tarifas_contrate[
                                aerolineCotizadorIdaFlight[indAero].indTarifa
                              ].cant_maletas_personal > 0
                            "
                            :color="$vuetify.theme.themes.light.primary"
                            small
                          >
                            {{ icons.mdiBagPersonal }}
                          </v-icon>
                          <v-icon
                            v-else
                            small
                            :color="$vuetify.theme.themes.light.default"
                          >
                            {{ icons.mdiBagPersonalOutline }}
                          </v-icon>
                        </v-col>
                        <v-col
                          cols="12"
                          md="10"
                          sm="10"
                        >
                          <h3 :style="`color:${$vuetify.theme.themes.light.primary}`">
                            <fragment
                              v-if="
                                aerolineCotizadorIdaFlight[indAero].flights[index].tarifas_contrate[
                                  aerolineCotizadorIdaFlight[indAero].indTarifa
                                ].cant_maletas_personal > 0
                              "
                            >
                              {{ $t('msg.bagInclude') }}
                            </fragment>
                            <fragment v-else>
                              {{ $t('msg.bagNoInclude') }}
                            </fragment>
                          </h3>
                          <p style="color:#666">{{ $t('msg.bagDescription') }}</p>
                        </v-col>

                        <v-col
                          cols="12"
                          md="12"
                          sm="12"
                        >
                          <v-divider
                            class="mb-2"
                            :style="`color:${$vuetify.theme.themes.light.primary}`"
                          ></v-divider>
                        </v-col>
                      </v-row>

                      <v-row
                        style="background-color: #ffffff"
                        no-gutters
                      >
                        <v-col
                          cols="12"
                          md="2"
                          sm="2"
                        >
                          <CarryOn
                            v-if="
                              aerolineCotizadorIdaFlight[indAero].flights[index].tarifas_contrate[
                                aerolineCotizadorIdaFlight[indAero].indTarifa
                              ].cant_maletas_cabina > 0
                            "
                            width="25"
                            height="25"
                            :color="$vuetify.theme.themes.light.primary"
                          />
                          <CarryOn
                            v-else
                            width="25"
                            height="25"
                            :color="$vuetify.theme.themes.light.default"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="10"
                          sm="10"
                        >
                          <h3 :style="`color:${$vuetify.theme.themes.light.primary}`">
                            <fragment
                              v-if="
                                aerolineCotizadorIdaFlight[indAero].flights[index].tarifas_contrate[
                                  aerolineCotizadorIdaFlight[indAero].indTarifa
                                ].cant_maletas_cabina > 0
                              "
                            >
                              {{ $t('msg.cabinaInclude') }}
                            </fragment>
                            <fragment v-else>
                              {{ $t('msg.cabinaNoInclude') }}
                            </fragment>
                          </h3>
                          <p style="color:#666">
                            {{ $t('msg.cabinaDescription') }}
                          </p>
                        </v-col>

                        <v-col
                          cols="12"
                          md="12"
                          sm="12"
                        >
                          <v-divider
                            class="mb-2"
                            :style="`color:${$vuetify.theme.themes.light.primary}`"
                          ></v-divider>
                        </v-col>
                      </v-row>

                      <v-row
                        style="background-color: #ffffff"
                        no-gutters
                      >
                        <v-col
                          cols="12"
                          md="2"
                          sm="2"
                        >
                          <Suitcase
                            v-if="
                              aerolineCotizadorIdaFlight[indAero].flights[index].tarifas_contrate[
                                aerolineCotizadorIdaFlight[indAero].indTarifa
                              ].cant_maletas_bodega > 0
                            "
                            width="25"
                            height="25"
                            :color="$vuetify.theme.themes.light.primary"
                          />
                          <Suitcase
                            v-else
                            width="25"
                            height="25"
                            :color="$vuetify.theme.themes.light.default"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="10"
                          sm="10"
                        >
                          <h3 :style="`color:${$vuetify.theme.themes.light.primary}`">
                            <fragment
                              v-if="
                                aerolineCotizadorIdaFlight[indAero].flights[index].tarifas_contrate[
                                  aerolineCotizadorIdaFlight[indAero].indTarifa
                                ].cant_maletas_bodega > 0
                              "
                            >
                              {{ $t('msg.bodegaInclude') }}
                            </fragment>
                            <fragment v-else>
                              {{ $t('msg.bodegaNoInclude') }}
                            </fragment>
                          </h3>
                          <p style="color:#666">
                            <fragment
                              v-if="
                                aerolineCotizadorIdaFlight[indAero].flights[index].tarifas_contrate[
                                  aerolineCotizadorIdaFlight[indAero].indTarifa
                                ].cant_maletas_bodega > 0
                              "
                            >
                              {{
                                $tc(
                                  'msg.bags',
                                  aerolineCotizadorIdaFlight[indAero].flights[index].tarifas_contrate[
                                    aerolineCotizadorIdaFlight[indAero].indTarifa
                                  ].cant_maletas_bodega,
                                )
                              }}
                            </fragment>
                            <fragment v-else></fragment>
                            <br />
                            {{ $t('msg.bodegaDescription') }}
                          </p>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </span>
              </v-tooltip>
            </p>
          </div>
        </v-col>
        <v-col cols="3">
          <p class="mb-0 pr-2 pt-4 pb-0">
            {{ aerolineCotizadorIdaFlight[indAero].flights[index].flight.aeronave.modelo }}
          </p>
        </v-col>
      </v-row>
      <v-expansion-panel-header
        ripple
        style="background-color: aliceblue; width:40px"
        class="d-flex justify-center align-center"
      >
      </v-expansion-panel-header>
    </div>
    <v-expansion-panel-content style="background-color: aliceblue;">
      <v-container
        fluid
        class="ma-2"
        style="background-color: white;"
      >
        <v-row
          v-if="aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia.length > 0"
          dense
          class="pt-0"
        >
          <v-col cols="12">
            <v-timeline
              align-top
              dense
              class="pt-0"
            >
              <v-timeline-item
                color="primary"
                small
              >
                <v-row class="pt-1">
                  <v-col cols="2">
                    <strong>{{
                      aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].origen.salida
                    }}</strong><br />
                    <span>{{ destinosCotizadorFlight[0].from | moment('D MMMM, YYYY') }}</span>
                  </v-col>
                  <v-col cols="10">
                    <strong class="pr-2">{{
                      aerolineCotizadorIdaFlight[indAero].flights[index].flight.iata_origen
                    }}</strong><br />
                    <span class="mb-0">
                      {{ aerolineCotizadorIdaFlight[indAero].flights[index].flight.origen.destino.name }},
                      {{
                        `${
                          aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].origen.terminal !==
                          null
                            ? `${$t('lbl.terminal')} ${
                              aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].origen.terminal
                            }`
                            : ''
                        }`
                      }}
                    </span>
                  </v-col>
                  <!--DURACION DEL VUELO-->
                  <v-col cols="12">
                    {{ $t('lbl.duration') }}
                    <span
                      v-if="
                        aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas.length === 0
                      "
                    >
                      {{ aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].duration }}
                    </span>
                    <span v-else>
                      {{
                        aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[0]
                          .llegadaDuration
                      }}
                    </span>
                    <br />
                  </v-col>
                </v-row>
              </v-timeline-item>

              <v-timeline-item
                v-if="
                  aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas.length === 0 ||
                    aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas === null
                "
                color="primary"
                small
              >
                <v-row class="pt-1">
                  <v-col cols="2">
                    <strong>{{
                      aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].destino.llegada
                    }}</strong><br />
                    <span>{{
                      destinosCotizadorFlight[0].from
                        | moment(
                          'add',
                          `${aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].days}  days`,
                          'D MMMM, YYYY',
                        )
                    }}</span>
                  </v-col>

                  <v-col cols="10">
                    <strong class="pr-2">{{
                      aerolineCotizadorIdaFlight[indAero].flights[index].flight.iata_destino
                    }}</strong><br />
                    <span class="mb-0">
                      {{ aerolineCotizadorIdaFlight[indAero].flights[index].flight.destino.destino.name }},
                      {{
                        `${
                          aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].destino.terminal !==
                          null
                            ? `${$t('lbl.terminal')} ${
                              aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].destino.terminal
                            }`
                            : ''
                        }`
                      }}
                    </span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    class="pt-4 pb-0"
                  >
                    <p class="mb-0 pr-2 py-0">
                      {{ $t('lbl.flight') }}
                      {{ aerolineCotizadorIdaFlight[indAero].flights[index].flight.numero_de_vuelo }}・{{
                        aerolineCotizadorIdaFlight[indAero].flights[index].flight.aeronave.modelo
                      }}
                    </p>
                  </v-col>
                </v-row>
              </v-timeline-item>
              <!--ESCALA 1-->
              <v-timeline-item
                v-else
                color="primary"
                small
              >
                <v-row class="pt-1">
                  <v-col cols="2">
                    <strong>{{
                      aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[0].llegada
                    }}</strong><br />
                    <span>{{
                      destinosCotizadorFlight[0].from
                        | moment(
                          'add',
                          `${aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[0].llegadaDay}  days`,
                          'D MMMM, YYYY',
                        )
                    }}</span>
                  </v-col>

                  <v-col cols="10">
                    <strong class="pr-2">{{
                      aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[0].city.iata
                    }}</strong><br />
                    <span class="mb-0">
                      {{
                        aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[0].city.name
                      }},
                      {{
                        `${
                          aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[0]
                            .llegadaTerminal !== null
                            ? `${$t('lbl.terminal')} ${
                              aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[0]
                                .llegadaTerminal
                            }`
                            : ''
                        }`
                      }}
                    </span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    class="pt-4 pb-0"
                  >
                    <p class="mb-0 pr-2 py-0">
                      {{ $t('lbl.flight') }}
                      {{ aerolineCotizadorIdaFlight[indAero].flights[index].flight.numero_de_vuelo }}・{{
                        aerolineCotizadorIdaFlight[indAero].flights[index].flight.aeronave.modelo
                      }}
                    </p>
                  </v-col>
                </v-row>
              </v-timeline-item>
              <!--DURACION DE LA ESCALA-->
              <v-row
                v-if="aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas.length > 0"
                no-gutters
                class="pb-5"
              >
                <v-col
                  cols="12"
                  md="2"
                  class="text-center pt-5"
                >
                  <v-divider :style="`color:${$vuetify.theme.themes.light.primary}`"></v-divider>
                </v-col>
                <v-col
                  cols="12"
                  md="8"
                >
                  <v-btn
                    rounded
                    outlined
                    color="primary"
                    block
                    style="text-transform: none;"
                  >
                    <v-icon left>
                      {{ icons.mdiProgressClock }}
                    </v-icon>
                    {{ $t('lbl.escalaIn') }}
                    {{ aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[0].city.name }}
                    {{
                      formatDuration(
                        aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[0]
                          .salidaDurationM,
                      )
                    }}
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                  class="text-center pt-5"
                >
                  <v-divider :style="`color:${$vuetify.theme.themes.light.primary}`"></v-divider>
                </v-col>
              </v-row>
            </v-timeline>

            <!--ESCALA 2-->
            <v-timeline
              v-if="aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas.length >= 2"
              align-top
              dense
              class="pt-0"
            >
              <v-timeline-item
                color="primary"
                small
              >
                <v-row class="pt-1">
                  <v-col cols="2">
                    <strong>{{
                      aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[0].salida
                    }}</strong><br />
                    <span>{{
                      destinosCotizadorFlight[0].from
                        | moment(
                          'add',
                          `${aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[0]
                            .llegadaDay +
                            aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[0]
                              .salidaDay +
                            aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[1]
                              .llegadaDay}  days`,
                          'D MMMM, YYYY',
                        )
                    }}</span>
                  </v-col>
                  <v-col cols="10">
                    <strong class="pr-2">{{
                      aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[0].city.iata
                    }}</strong><br />
                    <span class="mb-0">
                      {{
                        aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[0].city.name
                      }},
                      {{
                        `${
                          aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[0]
                            .salidaTerminal !== null
                            ? `${$t('lbl.terminal')} ${
                              aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[0]
                                .salidaTerminal
                            }`
                            : ''
                        }`
                      }}
                    </span>
                  </v-col>
                  <!--DURACION DEL VUELO-->
                  <v-col cols="12">
                    {{ $t('lbl.duration') }}
                    {{
                      aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[1].llegadaDuration
                    }}
                    <br />
                  </v-col>
                </v-row>
              </v-timeline-item>
              <v-timeline-item
                color="primary"
                small
              >
                <v-row class="pt-1">
                  <v-col cols="2">
                    <strong>{{
                      aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[1].llegada
                    }}</strong><br />
                    <span>{{
                      destinosCotizadorFlight[0].from
                        | moment(
                          'add',
                          `${aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[0]
                            .llegadaDay +
                            aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[0]
                              .salidaDay +
                            aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[1]
                              .llegadaDay +
                            aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[1]
                              .salidaDay}  days`,
                          'D MMMM, YYYY',
                        )
                    }}</span>
                  </v-col>

                  <v-col cols="10">
                    <strong class="pr-2">{{
                      aerolineCotizadorIdaFlight[indAero].flights[index].flight.escalas[1].city.iata
                    }}</strong><br />
                    <span class="mb-0">
                      {{ aerolineCotizadorIdaFlight[indAero].flights[index].flight.escalas[1].city.name }},
                      {{
                        `${
                          aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[1]
                            .llegadaTerminal !== null
                            ? `${$t('lbl.terminal')} ${
                              aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[1]
                                .llegadaTerminal
                            }`
                            : ''
                        }`
                      }}
                    </span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    class="pt-4 pb-0"
                  >
                    <p class="mb-0 pr-2 py-0">
                      {{ $t('lbl.flight') }}
                      {{ aerolineCotizadorIdaFlight[indAero].flights[index].flight.numero_de_vuelo }}・{{
                        aerolineCotizadorIdaFlight[indAero].flights[index].flight.aeronave.modelo
                      }}
                    </p>
                  </v-col>
                </v-row>
              </v-timeline-item>
              <!--DURACION DE LA ESCALA-->
              <v-row
                v-if="aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas.length > 1"
                no-gutters
                class="pb-5"
              >
                <v-col
                  cols="12"
                  md="2"
                  class="text-center pt-5"
                >
                  <v-divider :style="`color:${$vuetify.theme.themes.light.primary}`"></v-divider>
                </v-col>
                <v-col
                  cols="12"
                  md="8"
                >
                  <v-btn
                    rounded
                    outlined
                    color="primary"
                    block
                    style="text-transform: none;"
                  >
                    <v-icon left>
                      {{ icons.mdiProgressClock }}
                    </v-icon>
                    {{ $t('lbl.escalaIn') }}
                    {{ aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[1].city.name }}
                    {{
                      formatDuration(
                        aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[1]
                          .salidaDurationM,
                      )
                    }}
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                  class="text-center pt-5"
                >
                  <v-divider :style="`color:${$vuetify.theme.themes.light.primary}`"></v-divider>
                </v-col>
              </v-row>
            </v-timeline>

            <!--ESCALA 3-->
            <v-timeline
              v-if="aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas.length >= 3"
              align-top
              dense
              class="pt-0"
            >
              <v-timeline-item
                color="primary"
                small
              >
                <v-row class="pt-1">
                  <v-col cols="2">
                    <strong>{{
                      aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[1].salida
                    }}</strong><br />
                    <span>{{
                      destinosCotizadorFlight[0].from
                        | moment(
                          'add',
                          `${aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[0]
                            .llegadaDay +
                            aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[0]
                              .salidaDay +
                            aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[1]
                              .llegadaDay +
                            aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[1]
                              .salidaDay}  days`,
                          'D MMMM, YYYY',
                        )
                    }}</span>
                  </v-col>
                  <v-col cols="10">
                    <strong class="pr-2">{{
                      aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[1].city.iata
                    }}</strong><br />
                    <span class="mb-0">
                      {{
                        aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[1].city.name
                      }},
                      {{
                        `${
                          aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[1]
                            .salidaTerminal !== null
                            ? `${$t('lbl.terminal')} ${
                              aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[1]
                                .salidaTerminal
                            }`
                            : ''
                        }`
                      }}
                    </span>
                  </v-col>
                  <!--DURACION DEL VUELO-->
                  <v-col cols="12">
                    {{ $t('lbl.duration') }}
                    {{
                      aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[2].llegadaDuration
                    }}
                    <br />
                  </v-col>
                </v-row>
              </v-timeline-item>
              <v-timeline-item
                color="primary"
                small
              >
                <v-row class="pt-1">
                  <v-col cols="2">
                    <strong>{{
                      aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[2].llegada
                    }}</strong><br />
                    <span>{{
                      destinosCotizadorFlight[0].from
                        | moment(
                          'add',
                          `${aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[0]
                            .llegadaDay +
                            aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[0]
                              .salidaDay +
                            aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[1]
                              .llegadaDay +
                            aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[1]
                              .salidaDay +
                            aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[2]
                              .llegadaDay}  days`,
                          'D MMMM, YYYY',
                        )
                    }}</span>
                  </v-col>

                  <v-col cols="10">
                    <strong class="pr-2">{{
                      aerolineCotizadorIdaFlight[indAero].flights[index].flight.escalas[2].city.iata
                    }}</strong><br />
                    <span class="mb-0">
                      {{ aerolineCotizadorIdaFlight[indAero].flights[index].flight.escalas[2].city.name }},
                      {{
                        `${
                          aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[2]
                            .llegadaTerminal !== null
                            ? `${$t('lbl.terminal')} ${
                              aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[2]
                                .llegadaTerminal
                            }`
                            : ''
                        }`
                      }}
                    </span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    class="pt-4 pb-0"
                  >
                    <p class="mb-0 pr-2 py-0">
                      {{ $t('lbl.flight') }}
                      {{ aerolineCotizadorIdaFlight[indAero].flights[index].flight.numero_de_vuelo }}・{{
                        aerolineCotizadorIdaFlight[indAero].flights[index].flight.aeronave.modelo
                      }}
                    </p>
                  </v-col>
                </v-row>
              </v-timeline-item>
              <!--DURACION DE LA ESCALA-->
              <v-row
                v-if="aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas.length > 1"
                no-gutters
                class="pb-5"
              >
                <v-col
                  cols="12"
                  md="2"
                  class="text-center pt-5"
                >
                  <v-divider :style="`color:${$vuetify.theme.themes.light.primary}`"></v-divider>
                </v-col>
                <v-col
                  cols="12"
                  md="8"
                >
                  <v-btn
                    rounded
                    outlined
                    color="primary"
                    block
                    style="text-transform: none;"
                  >
                    <v-icon left>
                      {{ icons.mdiProgressClock }}
                    </v-icon>
                    {{ $t('lbl.escalaIn') }}
                    {{ aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[2].city.name }}
                    {{
                      formatDuration(
                        aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[2]
                          .salidaDurationM,
                      )
                    }}
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                  class="text-center pt-5"
                >
                  <v-divider :style="`color:${$vuetify.theme.themes.light.primary}`"></v-divider>
                </v-col>
              </v-row>
            </v-timeline>

            <!--DESTINO-->
            <v-timeline
              v-if="aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas.length > 0"
              align-top
              dense
              class="pt-0"
            >
              <v-timeline-item
                color="primary"
                small
              >
                <v-row class="pt-1">
                  <v-col cols="2">
                    <strong>{{
                      aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[
                        aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas.length - 1
                      ].salida
                    }}</strong><br />
                    <span>{{
                      destinosCotizadorFlight[0].from
                        | moment(
                          'add',
                          `${aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].days -
                            aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[
                              aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas.length - 1
                            ].salidaDay}  days`,
                          'D MMMM, YYYY',
                        )
                    }}</span>
                  </v-col>
                  <v-col cols="10">
                    <strong class="pr-2">
                      {{
                        aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[
                          aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas.length - 1
                        ].city.iata
                      }} </strong><br />
                    <span class="mb-0">
                      {{
                        aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[
                          aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas.length - 1
                        ].city.name
                      }},
                      {{
                        `${
                          aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[
                            aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas.length - 1
                          ].salidaTerminal !== null
                            ? `${$t('lbl.terminal')} ${
                              aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas[
                                aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].escalas
                                  .length - 1
                              ].salidaTerminal
                            }`
                            : ''
                        }`
                      }}
                    </span>
                  </v-col>
                  <!--DURACION DEL VUELO-->
                  <v-col cols="12">
                    {{ $t('lbl.duration') }}
                    {{ aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].destino.duration }}
                    <br />
                  </v-col>
                </v-row>
              </v-timeline-item>
              <v-timeline-item
                color="primary"
                small
              >
                <v-row class="pt-1">
                  <v-col cols="2">
                    <strong>{{
                      aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].destino.llegada
                    }}</strong><br />
                    <span>{{
                      destinosCotizadorFlight[0].from
                        | moment(
                          'add',
                          `${aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].days}  days`,
                          'D MMMM, YYYY',
                        )
                    }}</span>
                  </v-col>

                  <v-col cols="10">
                    <strong class="pr-2">{{
                      aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].destino.destino.iata
                    }}</strong><br />
                    <span class="mb-0">
                      {{
                        aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].destino.destino.name
                      }},
                      {{
                        `${
                          aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].destino.terminal !==
                          null
                            ? `${$t('lbl.terminal')} ${
                              aerolineCotizadorIdaFlight[indAero].flights[index].flight.frecuencia[0].destino.terminal
                            }`
                            : ''
                        }`
                      }}
                    </span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    class="pt-4 pb-0"
                  >
                    <p class="mb-0 pr-2 py-0">
                      {{ $t('lbl.flight') }}
                      {{ aerolineCotizadorIdaFlight[indAero].flights[index].flight.numero_de_vuelo }}・{{
                        aerolineCotizadorIdaFlight[indAero].flights[index].flight.aeronave.modelo
                      }}
                    </p>
                  </v-col>
                </v-row>
              </v-timeline-item>
            </v-timeline>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="12"
            sm="12"
            class="ml-10"
          >
            <p class="mb-0 ml-0 py-0">
              <v-icon color="primary">
                mdi-airplane-clock
              </v-icon>
              {{ $t('msg.horarioAirpor') }}
            </p>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="12"
            sm="12"
          >
            <v-divider :style="`color:${$vuetify.theme.themes.light.primary}`"></v-divider>
          </v-col>

          <v-col
            cols="12"
            md="6"
            sm="6"
          >
            <p class="mb-0 pr-2 py-0">
              <b>{{ $t('lbl.clase') }}</b>: {{ aerolineCotizadorIdaFlight[indAero].flights[index].tarifas_contrate[0].clase }}
              <br />
              <b>{{ $t('lbl.tarifa') }}</b>:
              {{ aerolineCotizadorIdaFlight[indAero].flights[index].tarifas_contrate[0].tarifa }}
              <span style="color: red">
                ({{ aerolineCotizadorIdaFlight[indAero].flights[index].tarifas_contrate[0].cupo_disponible }}
                {{ $t('lbl.cupo') }})
              </span>
            </p>
          </v-col>

          <v-col
            cols="12"
            md="12"
            sm="12"
          >
            <p>
              <v-icon small>
                {{ icons.mdiCloseThick }}
              </v-icon>
              {{ $t('lbl.noReembolsable') }}<br />

              <span :style="`color:${$vuetify.theme.themes.light.primary}`">
                <v-icon
                  small
                  color="primary"
                >
                  {{ icons.mdiCheckBold }}
                </v-icon>
                {{ $t('lbl.permiteCambios') }}
              </span>
            </p>
            {{ $t('lbl.cambiosView') }}
          </v-col>
        </v-row>
      </v-container>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiCar,
  mdiBed,
  mdiSeatPassenger,
  mdiWalletTravel,
  mdiAirplaneTakeoff,
  mdiAirplane,
  mdiAirplaneLanding,
  mdiSwapHorizontal,
  mdiOfficeBuilding,
  mdiEye,
  mdiPencil,
  mdiDelete,
  mdiPlusThick,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
  mdiChevronDown,
  mdiBagPersonal,
  mdiBagPersonalOff,
  mdiBagSuitcase,
  mdiBagSuitcaseOff,
  mdiBagCarryOn,
  mdiBagCarryOnOff,
  mdiBagSuitcaseOutline,
  mdiBagSuitcaseOffOutline,
  mdiBagPersonalOutline,
  mdiBagPersonalOffOutline,
  mdiCloseThick,
  mdiCheckBold,
  mdiInformation,
  mdiProgressClock,
} from '@mdi/js'

// eslint-disable-next-line import/no-unresolved
import Suitcase from '@/views/utils/images/suitcase.vue'
// eslint-disable-next-line import/no-unresolved
// import Backpack from '@/views/utils/images/backpack.vue'
// eslint-disable-next-line import/no-unresolved
import CarryOn from '@/views/utils/images/carry_on.vue'

export default {
  components: {
    Suitcase,

    // Backpack,
    CarryOn,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    indAero: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    index: {
      type: Number,
    },
  },
  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoadingTarifaEcon: false,
      radioGroup: 0,
      items: [],
      indexTarifa: 0,
      showOtherTarifas: false,
      rowShowOtherTarifas: -1,
      icons: {
        mdiCar,
        mdiBed,
        mdiSeatPassenger,
        mdiWalletTravel,
        mdiAirplaneTakeoff,
        mdiAirplane,
        mdiAirplaneLanding,
        mdiSwapHorizontal,
        mdiOfficeBuilding,
        mdiEye,
        mdiPencil,
        mdiDelete,
        mdiPlusThick,
        mdiChevronRight,
        mdiPlus,
        mdiMinus,
        mdiChevronDown,
        mdiBagPersonal,
        mdiBagPersonalOff,
        mdiBagSuitcase,
        mdiBagSuitcaseOff,
        mdiBagCarryOn,
        mdiBagCarryOnOff,
        mdiBagSuitcaseOutline,
        mdiBagSuitcaseOffOutline,
        mdiBagPersonalOutline,
        mdiBagPersonalOffOutline,
        mdiCloseThick,
        mdiCheckBold,
        mdiInformation,
        mdiProgressClock,
      },
    }
  },
  computed: {
    ...mapState({
      cotizadorFlight: state => state.app.cotizadorFlight,
      destinosCotizadorFlight: state => state.app.destinosCotizadorFlight,
      resultCotizadorIdaFlight: state => state.app.resultCotizadorIdaFlight,
      tarifaEconCotizadorIdaFlight: state => state.app.tarifaEconCotizadorIdaFlight,
      aerolineCotizadorIdaFlight: state => state.app.aerolineCotizadorIdaFlight,
    }),
  },
  methods: {
    ...mapMutations(['setTarifaEconCotizadorIdaFligth', 'setIndexTarifaEconCotizadorIdaFligth']),
    setRadioIda(index) {
      this.isLoadingTarifaEcon = true

      // this.indexTarifa = index

      // const fligtSelect = this.resultCotizadorIdaFlight[this.radioGroup]
      // this.setTarifaEconCotizadorIdaFligth(fligtSelect.tarifa_economica)

      // this.isLoadingTarifaEcon = false
      this.setIndexTarifaEconCotizadorIdaFligth({ indAero: this.indAero, index })

      // console.log(this.aerolineCotizadorIdaFlight[this.indAero].flights[index].tarifa_economica)
    },
    formatDuration(minutes) {
      let textHours
      let days = 0
      let hours = Math.floor(minutes / 60)
      const mins = minutes % 60
      if (hours > 24) {
        days = Math.floor(hours / 24)
        hours %= 24
        textHours = `${days}d`
      }

      if (days > 0) {
        textHours = `${textHours} ${hours}h ${mins}min.`
      } else {
        textHours = `${hours}h ${mins}min.`
      }

      return textHours
    },
  },
}
</script>

<style scoped>
/* Disponibilidad vuelo */
#titleDispVueloMine {
  background-color: #2d3137;
  color: white;
  padding: 10px 12px;
}

#titleDispVueloMine > div > p {
  color: white !important;
  font-size: 14px;
}

#titleDispVueloMine > div > h5 {
  color: white !important;
}

#fromToHeadMine p {
  font-size: 14px;
}

#expandDispMine p {
  font-size: 12px;
  padding: 5px 0px;
}

.v-timeline:before {
  bottom: 0;
  content: '';
  height: 30%;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

.v-timeline:before {
  bottom: 0;
  content: '';
  height: 40% !important;
  position: absolute;
  top: 25px !important;
  width: 2px;
}

.p14Mine {
  font-size: 14px;
}

.p12Mine {
  font-size: 12px;
}
</style>

<template>
  <fragment>
    <v-row class="my-2">
      <v-col cols="12">
        <v-card>
          <v-container grid-list-xs>
            <div class="d-flex justify-start">
              <v-icon :color="cotizadorFlightFavorite.length > 0 ? 'primary' : '' ">
                {{ icons.mdiHeart }}
              </v-icon>
              <p class="mb-0 ml-1">
                {{ cotizadorFlightFavorite.length }}
              </p>
              <v-divider
                vertical
                class="mx-3"
              ></v-divider>
              <p class="mb-0 ml-1">
                <span
                  v-if="cotizadorFlightFavorite.length > 0"
                  style="cursor: pointer;"
                  :style="`color: ${$vuetify.theme.themes.light.primary}`"
                  @click="getFavorite"
                >{{ $t('lbl.prepararSelect') }}</span>
                <span v-else>{{ $t('lbl.prepararSelect') }}</span>
              </p>
              <fragment>
                <v-spacer></v-spacer>
                <v-divider
                  vertical
                  class="mx-3"
                ></v-divider>
                <v-icon :color="cotizadorFlightFavorite.length > 0 ? 'primary' : '' ">
                  {{ icons.mdiHeartBroken }}
                </v-icon>
                <p class="mb-0 ml-1">
                  <span
                    style="cursor: pointer"
                    @click="clearFavorites"
                  >{{ $t('lbl.borrarSelect') }}</span>
                </p>
              </fragment>
            </div>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="aerolineCotizadorRegresoFlight.length > 0">
      <v-col
        v-for="(aeroline, indAero) in aerolineCotizadorRegresoFlight"
        :key="indAero"
        cols="12"
      >
        <IdaBackAerolinea
          :ind-aero="indAero"
        />
      </v-col>
    </v-row>
    <NoResults v-else />
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiCar,
  mdiBed,
  mdiSeatPassenger,
  mdiWalletTravel,
  mdiAirplaneTakeoff,
  mdiAirplane,
  mdiAirplaneLanding,
  mdiSwapHorizontal,
  mdiOfficeBuilding,
  mdiEye,
  mdiPencil,
  mdiDelete,
  mdiPlusThick,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
  mdiHeart,
  mdiHeartBroken,
} from '@mdi/js'
// eslint-disable-next-line import/no-unresolved
import IdaBackAerolinea from './utils/IdaBackAerolinea.vue'
import NoResults from '../../utils/NoResults.vue'

export default {
  components: {
    IdaBackAerolinea,
    NoResults,
  },
  data() {
    return {
      dialog2: false,
      aerolinea: [],
      aerolin: null,
      radioGroup: 1,
      icons: {
        mdiCar,
        mdiBed,
        mdiSeatPassenger,
        mdiWalletTravel,
        mdiAirplaneTakeoff,
        mdiAirplane,
        mdiAirplaneLanding,
        mdiSwapHorizontal,
        mdiOfficeBuilding,
        mdiEye,
        mdiPencil,
        mdiDelete,
        mdiPlusThick,
        mdiChevronRight,
        mdiPlus,
        mdiMinus,
        mdiHeart,
        mdiHeartBroken,
      },
    }
  },
  computed: {
    ...mapState({
      cotizadorFlight: state => state.app.cotizadorFlight,
      destinosCotizadorFlight: state => state.app.destinosCotizadorFlight,
      aerolineCotizadorRegresoFlight: state => state.app.aerolineCotizadorRegresoFlight,
      cotizadorFlightFavorite: state => state.app.cotizadorFlightFavorite,
    }),
  },
  created() {
    // this.getDisponibilidadFlightRegreso()
  },
  methods: {
    ...mapMutations(['deleteDestinoCotizadorFligth', 'clearCotizadorFlightFavorite', 'clearIdFlightFavorite']),
    getDisponibilidadFlightRegreso() {
      // console.log(this.aerolineCotizadorRegresoFlight)
      if (this.aerolineCotizadorRegresoFlight.length === 0) {
        this.$router.push({ name: 'cotizador' })
      }
      setTimeout(() => {
        this.isLoading = false
      }, 100)
    },
    clearFavorites() {
      this.clearCotizadorFlightFavorite()
      this.clearIdFlightFavorite()
    },
    getFavorite() {
      this.$router.push({ name: 'cotizador-favorite' })
    },
  },
}
</script>

<style>
.theme--light.v-text-field.v-input--is-disabled .v-input__slot:before {
  border: none;
}

.v-input__slot {
  margin-bottom: 0px !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div
  > div
  > div {
  padding: 0 !important;
}

#app
  > div.v-application--wrap
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div
  > div
  > div
  > div
  > div:nth-child(3)
  > div {
  padding: 0 !important;
  margin-top: 0 !important;
}

#app
  > div.v-application--wrap
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div
  > div
  > div
  > div
  > div:nth-child(4)
  > div {
  padding: 0 !important;
  margin-top: 0 !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div::before {
  box-shadow: none !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div {
  box-shadow: none !important;
  padding-bottom: 10px;
}
#app
  > div.v-application--wrap
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(2)
  > div.pl-0.col-md-3.col-12
  > div
  > div
  > div
  > div
  > label {
  padding-left: 10px !important;
  z-index: 999 !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div {
  box-shadow: none !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div {
  box-shadow: none !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div::before {
  box-shadow: none !important;
}
.boton-lefttMine,
.boton-rightMine {
  max-height: 20px !important;
  min-width: 40px !important;
  padding: 0px !important;
  border: none !important;
}

#weirdButMine {
  border: 1px solid #dcdade;
  max-height: 40px;
  border-radius: 5px;
}

#weirdButMine input {
  height: 20px;
  text-align: center;
}

#weirdButMine div:nth-child(2) > div {
  height: 20px;
  padding: 0;
  margin: 0;
}

#weirdButMine .v-input__slot:after {
  border: none !important;
}

#weirdButMine .v-input__slot:before {
  border: none;
}

#weirdButMine button::before {
  background-color: rgba(255, 255, 255, 0);
}

#weirdButMine button .v-ripple__container {
  color: rgba(255, 255, 255, 0);
  background-color: rgba(255, 255, 255, 0);
}

/* PARA LA FECHA DE VCALENDAR*/
.vc-popover-content-wrapper {
  margin: 0px;
  position: fixed !important;
  inset: 0px auto auto 0px;
  transform: translate(150px, 80px);
}
</style>

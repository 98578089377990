<template>
  <div class="h-full">
    <div class="drawer-header d-flex align-center mb-4">
      <span class="font-weight-semibold text-base text--primary">
        {{ $t('lbl.filtros') }}
      </span>
      <v-spacer></v-spacer>
      <v-btn
        icon
        large
        @click="$emit('close-drawer')"
      >
        <v-icon size="22">
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>
    <perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="ps-calendar-event-handler"
    >
      <v-form
        ref="refCalendarEventHandlerForm"
        class="px-5 pt-3 pb-10"
      >
        <v-select
          v-model="filtrosCotizadorFlight.aerolinea"
          :items="aerolineas"
          :label="$t('permiso.aerolinea')"
          outlined
          dense
          hide-details
          item-text="name"
          item-value="id"
          cols="12"
        ></v-select>

        <h4
          class="pl-0 mb-0 pr-6 pt-5"
          subtitle-2
        >
          {{ $t('lbl.escalas') }}:
        </h4>
        <v-radio-group
          v-model="filtrosCotizadorFlight.escala"
          row
        >
          <v-radio
            dense
            :label="$t('lbl.escala1')"
          ></v-radio>
          <v-radio
            dense
            :label="$t('lbl.escala2')"
          ></v-radio>
          <v-radio
            dense
            :label="$t('lbl.escala3')"
          ></v-radio>
        </v-radio-group>

        <fragment cols="6">
          <h4
            class="pl-0 mb-0 pr-6"
            subtitle-2
          >
            {{ $t('lbl.durationEscala') }}:
            <strong color="primary">{{
              $t('lbl.durationEscalaRango', { time: filtrosCotizadorFlight.duracionEscala })
            }}</strong>
          </h4>
          <v-slider
            v-model="filtrosCotizadorFlight.duracionEscala"
            max="24"
            min="0"
            hide-details
          ></v-slider>
        </fragment>

        <fragment cols="6">
          <h4
            class="pl-0 mb-0 pr-6"
            subtitle-2
          >
            {{ $t('lbl.durationViaje') }}:
            <strong>{{ $t('lbl.durationEscalaRango', { time: filtrosCotizadorFlight.duracionViaje }) }}</strong>
          </h4>
          <v-slider
            v-model="filtrosCotizadorFlight.duracionViaje"
            max="24"
            min="0"
            hide-details
          ></v-slider>
        </fragment>

        <fragment>
          <h4
            class="pl-0 pr-6 mb-2"
            subtitle-2
          >
            {{ $t('lbl.frecuencias') }}:
          </h4>
          <v-row>
            <v-col
              cols="4"
              class="pb-0"
            >
              <v-checkbox
                v-model="frecuencias"
                value="0"
                class="mt-0"
                label="Domingo"
                hide-details
                @change="setFrecuencia()"
              ></v-checkbox>
            </v-col>
            <v-col
              cols="3"
              class="pb-0"
            >
              <v-checkbox
                v-model="frecuencias"
                value="1"
                class="mt-0"
                label="Lunes"
                hide-details
                @change="setFrecuencia()"
              >
              </v-checkbox>
            </v-col>
            <v-col
              cols="3"
              class="pb-0"
            >
              <v-checkbox
                v-model="frecuencias"
                value="2"
                class="mt-0"
                label="Martes"
                hide-details
                @change="setFrecuencia()"
              ></v-checkbox>
            </v-col>
            <v-col
              cols="4"
              class="pb-0"
            >
              <v-checkbox
                v-model="frecuencias"
                value="3"
                class="mt-0"
                label="Miércoles"
                hide-details
                @change="setFrecuencia()"
              >
              </v-checkbox>
            </v-col>
            <v-col
              cols="3"
              class="pb-0"
            >
              <v-checkbox
                v-model="frecuencias"
                value="4"
                class="mt-0"
                label="Jueves"
                hide-details
                @change="setFrecuencia()"
              ></v-checkbox>
            </v-col>
            <v-col
              cols="3"
              class="pb-0"
            >
              <v-checkbox
                v-model="frecuencias"
                value="5"
                class="mt-0"
                label="Viernes"
                hide-details
                @change="setFrecuencia()"
              ></v-checkbox>
            </v-col>
            <v-col
              cols="4"
              class="pb-0"
            >
              <v-checkbox
                v-model="frecuencias"
                value="6"
                class="mt-0"
                label="Sábado"
                hide-details
                @change="setFrecuencia()"
              ></v-checkbox>
            </v-col>
          </v-row>
        </fragment>

        <fragment>
          <v-autocomplete
            v-model="filtrosCotizadorFlight.airport"
            :items="itemsOrigen"
            :search-input.sync="searchOrigen"
            :label="$t('lbl.airport')"
            outlined
            dense
            item-text="name"
            item-value="id"
            return-object
            single-line
            hide-details
            clearable
            class="pt-8"
            cols="6"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.airport') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:item="{ item }">
              <v-icon left>
                {{ icons.mdiAirplaneTakeoff }}
              </v-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-slot:selection="{ item }">
              {{ item.name }}
            </template>
          </v-autocomplete>
        </fragment>

        <fragment>
          <v-select
            v-model="filtrosCotizadorFlight.clase"
            :items="clases"
            :label="$t('lbl.clase')"
            outlined
            dense
            hide-details
            item-text="name"
            item-value="id"
            class="pt-5"
          ></v-select>
        </fragment>

        <fragment>
          <h4
            class="pl-0 mb-0 pr-6 pt-5"
            subtitle-2
          >
            {{ $t('lbl.bag') }}:
          </h4>
          <v-radio-group
            v-model="filtrosCotizadorFlight.equipaje"
            row
          >
            <v-radio
              dense
              label="Indiferente"
            ></v-radio>
            <v-radio
              dense
              label="Sin equipaje"
            ></v-radio>
            <v-radio
              dense
              label="Con equipaje"
            ></v-radio>
          </v-radio-group>
        </fragment>

        <v-btn
          width="100%"
          color="primary"
          text
          :loading="loading"
          outlined
          @click="aplicarFiltros"
        >
          {{ $t('btn.apply') }}
        </v-btn>
      </v-form>
    </perfect-scrollbar>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiCar,
  mdiBed,
  mdiSeatPassenger,
  mdiWalletTravel,
  mdiAirplaneTakeoff,
  mdiAirplane,
  mdiAirplaneLanding,
  mdiSwapHorizontal,
  mdiOfficeBuilding,
  mdiEye,
  mdiPencil,
  mdiDelete,
  mdiPlusThick,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
  mdiClose,
} from '@mdi/js'

export default {
  data() {
    return {
      isLoadingFlihgt: true,
      loading: false,
      dialog: false,
      dialogm1: false,
      aerolineas: [],
      aerolinea: [],
      clases: [],
      aerolin: null,
      frecuencias: [],
      itemsOrigen: [],
      searchOrigen: null,
      icons: {
        mdiCar,
        mdiBed,
        mdiSeatPassenger,
        mdiWalletTravel,
        mdiAirplaneTakeoff,
        mdiAirplane,
        mdiAirplaneLanding,
        mdiSwapHorizontal,
        mdiOfficeBuilding,
        mdiEye,
        mdiPencil,
        mdiDelete,
        mdiPlusThick,
        mdiChevronRight,
        mdiPlus,
        mdiMinus,
        mdiClose,
      },
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
        wheelSpeed: 0.7,
      },
    }
  },
  computed: {
    ...mapState({
      cotizadorFlight: state => state.app.cotizadorFlight,
      filtrosCotizadorFlight: state => state.app.filtrosCotizadorFlight,
      destinosCotizadorFlight: state => state.app.destinosCotizadorFlight,
      aerolineCotizadorIdaFlight: state => state.app.aerolineCotizadorIdaFlight,
      aerolineCotizadorRegresoFlight: state => state.app.aerolineCotizadorRegresoFlight,
    }),
  },
  watch: {
    searchOrigen(val) {
      if (val.length > 2) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterOrigen(val.toLowerCase())
      }
    },
  },
  created() {
    this.frecuencias = this.filtrosCotizadorFlight.frecuencias
    this.getAerolineas()
    this.getClases()
  },
  methods: {
    ...mapMutations([
      'setFiltrosCotizadorFlight',
      'setAerolineCotizadorIdaFligth',
      'setAerolineCotizadorRegresoFligth',
      'setSearchCotizadorIdaFlight',
      'setSearchCotizadorFlights',
    ]),
    getAerolineas() {
      this.axios
        .post('cotizador-airlines', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.aerolineas = res.data.data.data
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoadingFlihgt = false))
    },
    getClases() {
      this.axios
        .get('nom_clase_flights?per_page=10000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.clases.push({
              id: 0,
              name: 'Todas',
            })
            res.data.data.forEach(element => {
              this.clases.push({
                id: element.id,
                name: element.name,
              })
            })
          }
        })
    },
    setFrecuencia() {
      this.setFiltrosCotizadorFlight({
        frecuencias: this.frecuencias,
      })
    },
    filterOrigen(v) {
      this.itemsOrigen = []
      // eslint-disable-next-line no-empty
      if (v !== '') {
        this.axios
          .post(
            'airports-names-filter-origen-contrate',
            { search: v },
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          .then(res => {
            const result = res.data.data.data

            result.forEach(element => {
              // eslint-disable-next-line no-nested-ternary
              this.itemsOrigen.push({
                id: element.id,
                name: element.nameComodin,
                iataComodin: element.iataComodin,
                nombre: element.name,
                iata: element.iata,
              })
            })
          })
      }
    },
    byMejorOpcion() {
      this.setFiltrosCotizadorFlight({
        byMejorOpcion: true,
        byMasEconomico: false,
        byMasRapido: false,
      })
      this.aplicarFiltros()
    },
    byMasEconomico() {
      this.setFiltrosCotizadorFlight({
        byMejorOpcion: false,
        byMasEconomico: true,
        byMasRapido: false,
      })
      this.aplicarFiltros()
    },
    byMasRapido() {
      this.setFiltrosCotizadorFlight({
        byMejorOpcion: false,
        byMasEconomico: false,
        byMasRapido: true,
      })
      this.aplicarFiltros()
    },
    aplicarFiltros() {
      // this.dialog = false
      // console.log(this.filtrosCotizadorFlight)
      this.setFiltrosCotizadorFlight({
        filtrosCotizadorFlight: this.filtrosCotizadorFlight,
      })
      this.setSearchCotizadorIdaFlight(true)
      this.setSearchCotizadorFlights(true)

      // console.log(this.filtrosCotizadorFlight)

      this.loading = true

      const json = {
        cant_adults: this.cotizadorFlight.cant_adults,
        cant_ninnos: this.cotizadorFlight.cant_ninnos,
        cant_infant: this.cotizadorFlight.cant_infant,
        onlyIda: this.cotizadorFlight.onlyIda,
        destinosMultiples: this.cotizadorFlight.destinosMultiples,

        destinos: this.destinosCotizadorFlight,

        aerolinea: this.filtrosCotizadorFlight.aerolinea,
        escala: this.filtrosCotizadorFlight.escala,
        duracionEscala: this.filtrosCotizadorFlight.duracionEscala,
        duracionViaje: this.filtrosCotizadorFlight.duracionViaje,
        frecuencias: this.filtrosCotizadorFlight.frecuencias,
        airport: this.filtrosCotizadorFlight.airport,
        clase: this.filtrosCotizadorFlight.clase,
        equipaje: this.filtrosCotizadorFlight.equipaje,
        byMejorOpcion: this.filtrosCotizadorFlight.byMejorOpcion,
        byMasEconomico: this.filtrosCotizadorFlight.byMasEconomico,
        byMasRapido: this.filtrosCotizadorFlight.byMasRapido,
      }

      // console.log(json)

      this.axios
        .post('cotizador-search-flight', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // console.log([res.data.data.aerolineIda[0]])

          // console.log(res)

          this.setAerolineCotizadorIdaFligth(res.data.data.aerolineIda)

          this.setAerolineCotizadorRegresoFligth(res.data.data.aerolineRegreso)

          this.dialog = false
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.loading = false
          this.setSearchCotizadorIdaFlight(false)
          this.$emit('close-drawer')
        })

      /* if (this.cotizadorFlight.onlyIda) {
        console.log(this.aerolineCotizadorIdaFlight)
      } else if (!this.cotizadorFlight.onlyIda && !this.cotizadorFlight.destinosMultiples) {
        console.log(this.aerolineCotizadorRegresoFlight)
      } else {
        console.log('multidestinos')
      } */
    },
  },
}
</script>

<style scoped>
  .ps {
    height: 550px;
  }
</style>

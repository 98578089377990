<template>
  <v-card>
    <v-card-title
      id="titleDispVueloMine"
      class="primary"
    >
      <!--<v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <img
            :src="rutaPublic + aerolineCotizadorDestinosFlight[indAero].destinos[0].flights[0].flight.aerolinea.isotipo"
            :alt="aerolineCotizadorDestinosFlight[indAero].destinos[0].flights[0].flight.aerolinea.slug"
            height="25"
            color="white"
            v-bind="attrs"
            v-on="on"
          >
        </template>
        <span>{{ aerolineCotizadorDestinosFlight[indAero].destinos[0].flights[0].flight.aerolinea.name }}</span>
      </v-tooltip>
      <div class="d-flex justify-start align-baseline px-2">
        <p class="mb-0 pr-2">
          {{ $t('lbl.aerolinea') }}:
        </p>
        <h5
          class="mb-0"
          style="font-weight: bold;"
        >
          {{ aerolineCotizadorDestinosFlight[indAero].destinos[0].flights[0].flight.aerolinea.name }}
        </h5>
      </div>-->
      <v-spacer></v-spacer>
      <div class="d-flex justify-start align-baseline px-2">
        <p class="mb-0 pr-2">
          Ticket Time Limit:
        </p>
        <h5 class="mb-0">
          50 minutos
        </h5>
      </div>
    </v-card-title>
    <fragment>
      <DestinoAeroline
        v-for="(destino, index) in aerolineCotizadorDestinosFlight[indAero].destinos"
        :key="index"
        :ind-aero="indAero"
        :ind-destino="index"
      />
    </fragment>

    <v-divider class="px-0 py-3"></v-divider>
    <!--TARIFA ECONOMICA-->
    <IdaDestinosTarifaEconomica
      :ind-aero="indAero"
    />
  </v-card>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiCar,
  mdiBed,
  mdiSeatPassenger,
  mdiWalletTravel,
  mdiAirplaneTakeoff,
  mdiAirplane,
  mdiAirplaneLanding,
  mdiSwapHorizontal,
  mdiOfficeBuilding,
  mdiEye,
  mdiPencil,
  mdiDelete,
  mdiPlusThick,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
  mdiChevronDown,
  mdiBagPersonal,
  mdiBagSuitcase,
  mdiInformation,
} from '@mdi/js'

// eslint-disable-next-line import/no-unresolved
import DestinoAeroline from './DestinoAeroline.vue'
import IdaDestinosTarifaEconomica from './IdaDestinosTarifaEconomica.vue'

export default {
  components: {
    DestinoAeroline,
    IdaDestinosTarifaEconomica,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    indAero: {
      type: Number,
    },
  },
  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoadingTarifaEcon: false,
      radioGroup: 0,
      items: [],
      indexTarifa: 0,
      showOtherTarifas: false,
      rowShowOtherTarifas: -1,
      icons: {
        mdiCar,
        mdiBed,
        mdiSeatPassenger,
        mdiWalletTravel,
        mdiAirplaneTakeoff,
        mdiAirplane,
        mdiAirplaneLanding,
        mdiSwapHorizontal,
        mdiOfficeBuilding,
        mdiEye,
        mdiPencil,
        mdiDelete,
        mdiPlusThick,
        mdiChevronRight,
        mdiPlus,
        mdiMinus,
        mdiChevronDown,
        mdiBagPersonal,
        mdiBagSuitcase,
        mdiInformation,
      },
    }
  },
  computed: {
    ...mapState({
      cotizadorFlight: state => state.app.cotizadorFlight,
      destinosCotizadorFlight: state => state.app.destinosCotizadorFlight,
      resultCotizadorIdaFlight: state => state.app.resultCotizadorIdaFlight,
      tarifaEconCotizadorIdaFlight: state => state.app.tarifaEconCotizadorIdaFlight,
      aerolineCotizadorDestinosFlight: state => state.app.aerolineCotizadorDestinosFlight,
    }),
  },
  methods: {
    ...mapMutations(['setTarifaEconCotizadorIdaFligth']),
  },
}
</script>

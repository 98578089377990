<template>
  <fragment>
    <v-container
      id="fromToHeadMine"
      grid-list-xs
    >
      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-space-between align-center">
            <div class="d-block justify-center">
              <div class="d-flex justify-start align-center">
                <h2>{{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].origen.state }}</h2>
                <v-icon
                  class="px-2"
                  color="primary"
                >
                  {{ icons.mdiArrowRight }}
                </v-icon>
                <h2>{{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].destino.state }}</h2>
                <h2 class="px-2">
                  .
                </h2>
                <p class="mb-0 mt-2">
                  {{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].from | moment("MMMM D, YYYY, dddd") }}
                </p>
              </div>
              <div class="d-flex justify-start align-center">
                <p class="mb-0 pr-2">
                  {{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].origen.name }}
                </p>
                <h4 class="mb-0 pr-2">
                  {{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].origen.iata }}
                </h4>
                <h4 class="mb-0 pr-2">
                  →
                </h4>
                <p class="mb-0 pr-2">
                  {{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].destino.name }}
                </p>
                <h4 class="mb-0">
                  {{ aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].destino.iata }}
                </h4>
              </div>
            </div>
            <div>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <img
                    :src="rutaPublic + aerolineCotizadorDestinosFlight[indAero].destinos[0].flights[0].flight.aerolinea.isotipo"
                    :alt="aerolineCotizadorDestinosFlight[indAero].destinos[0].flights[0].flight.aerolinea.slug"
                    height="40"
                    color="white"
                    v-bind="attrs"
                    v-on="on"
                  >
                </template>
                <span>{{ aerolineCotizadorDestinosFlight[indAero].destinos[0].flights[0].flight.aerolinea.name }}</span>
              </v-tooltip>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container
      id="expandDispMine"
      grid-list-xs
    >
      <v-row>
        <v-col
          cols="12"
          class="pt-0"
        >
          <v-radio-group
            v-model="radioGroup"
            class="mt-0 pt-0"
            mandatory
          >
            <v-expansion-panels
              accordion
              multiple
            >
              <IdaDestinoFlight
                v-for="(item, indexF) in aerolineCotizadorDestinosFlight[indAero].destinos[indDestino].flights"
                :key="indexF"
                :index="indexF"
                :ind-aero="indAero"
                :ind-destino="indDestino"
              />
            </v-expansion-panels>
          </v-radio-group>
        </v-col>
      </v-row>
    </v-container>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiCar,
  mdiBed,
  mdiSeatPassenger,
  mdiWalletTravel,
  mdiAirplaneTakeoff,
  mdiAirplane,
  mdiAirplaneLanding,
  mdiSwapHorizontal,
  mdiArrowRight,
  mdiOfficeBuilding,
  mdiEye,
  mdiPencil,
  mdiDelete,
  mdiPlusThick,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
  mdiChevronDown,
  mdiBagPersonal,
  mdiBagSuitcase,
  mdiInformation,
} from '@mdi/js'

// eslint-disable-next-line import/no-unresolved
import IdaDestinoFlight from './IdaDestinoFlight.vue'

export default {
  components: {
    IdaDestinoFlight,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    indAero: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    indDestino: {
      type: Number,
    },
  },
  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoadingTarifaEcon: false,
      radioGroup: 0,
      items: [],
      indexTarifa: 0,
      showOtherTarifas: false,
      rowShowOtherTarifas: -1,
      icons: {
        mdiCar,
        mdiBed,
        mdiSeatPassenger,
        mdiWalletTravel,
        mdiAirplaneTakeoff,
        mdiAirplane,
        mdiAirplaneLanding,
        mdiSwapHorizontal,
        mdiArrowRight,
        mdiOfficeBuilding,
        mdiEye,
        mdiPencil,
        mdiDelete,
        mdiPlusThick,
        mdiChevronRight,
        mdiPlus,
        mdiMinus,
        mdiChevronDown,
        mdiBagPersonal,
        mdiBagSuitcase,
        mdiInformation,
      },
    }
  },
  computed: {
    ...mapState({
      cotizadorFlight: state => state.app.cotizadorFlight,
      destinosCotizadorFlight: state => state.app.destinosCotizadorFlight,
      resultCotizadorIdaFlight: state => state.app.resultCotizadorIdaFlight,
      tarifaEconCotizadorIdaFlight: state => state.app.tarifaEconCotizadorIdaFlight,
      aerolineCotizadorDestinosFlight: state => state.app.aerolineCotizadorDestinosFlight,
    }),
  },
  methods: {
    ...mapMutations(['setTarifaEconCotizadorIdaFligth']),
  },
}
</script>

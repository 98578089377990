<template>
  <fragment>
    <v-row class="my-2">
      <v-col cols="12">
        <v-card>
          <v-container grid-list-xs>
            <div class="d-flex justify-start">
              <v-icon :color="cotizadorFlightFavorite.length > 0 ? 'primary' : '' ">
                {{ icons.mdiHeart }}
              </v-icon>
              <p class="mb-0 ml-1">
                {{ cotizadorFlightFavorite.length }}
              </p>
              <v-divider
                vertical
                class="mx-3"
              ></v-divider>
              <p class="mb-0 ml-1">
                <span
                  v-if="cotizadorFlightFavorite.length > 0"
                  style="cursor: pointer;"
                  :style="`color: ${$vuetify.theme.themes.light.primary}`"
                  @click="getFavorite"
                >{{ $t('lbl.prepararSelect') }}</span>
                <span v-else>{{ $t('lbl.prepararSelect') }}</span>
              </p>
              <fragment>
                <v-spacer></v-spacer>
                <v-divider
                  vertical
                  class="mx-3"
                ></v-divider>
                <v-icon :color="cotizadorFlightFavorite.length > 0 ? 'primary' : '' ">
                  {{ icons.mdiHeartBroken }}
                </v-icon>
                <p class="mb-0 ml-1">
                  <span
                    style="cursor: pointer"
                    @click="clearFavorites"
                  >{{ $t('lbl.borrarSelect') }}</span>
                </p>
              </fragment>
            </div>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="aerolineCotizadorDestinosFlight.length > 0">
      <v-col
        v-for="(aerolinea, indAero) in aerolineCotizadorDestinosFlight"
        :key="indAero"
        cols="12"
      >
        <IdaAerolineaDestinos
          :ind-aero="indAero"
        />
        <!--<v-card>
          <v-card-title
            id="titleDispVueloMine"
            style="background-color:#9155fd;"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="white"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiPlus }}
                </v-icon>
              </template>
              <span>Aeromexico</span>
            </v-tooltip>
            <div class="d-flex justify-start align-baseline px-2">
              <p class="mb-0 pr-2">
                Aerolínea:
              </p>
              <h5
                class="mb-0"
                style="font-weight: bold;"
              >
                Aeromexico
              </h5>
            </div>
            <v-spacer></v-spacer>
            <div class="d-flex justify-start align-baseline px-2">
              <p class="mb-0 pr-2">
                Ticket Time Limit:
              </p>
              <h5 class="mb-0">
                50 minutos
              </h5>
            </div>
          </v-card-title>
          <v-container
            id="fromToHeadMine"
            grid-list-xs
          >
            <v-row>
              <v-col cols="12">
                <div class="d-flex justify-space-between align-center">
                  <div class="d-block justify-center">
                    <div class="d-flex justify-start align-center">
                      <h2>Nueva York</h2>
                      <v-icon
                        class="px-2"
                        color="primary"
                      >
                        {{ icons.mdiSwapHorizontal }}
                      </v-icon>
                      <h2>Madrid</h2>
                      <h2 class="px-2">
                        .
                      </h2>
                      <p class="mb-0 mt-2">
                        10 jun., 2022, vie.
                      </p>
                    </div>
                    <div class="d-flex justify-start align-center">
                      <p class="mb-0 pr-2">
                        Adolfo Suarez Madrid-Barajas Airport
                      </p>
                      <h4 class="mb-0 pr-2">
                        MAD
                      </h4>
                      <h4 class="mb-0 pr-2">
                        →
                      </h4>
                      <p class="mb-0 pr-2">
                        La Aurora International Airport
                      </p>
                      <h4 class="mb-0">
                        GUA
                      </h4>
                    </div>
                  </div>
                  <div>
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          x-large
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ icons.mdiPlus }}
                        </v-icon>
                      </template>
                      <span>Tooltip</span>
                    </v-tooltip>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
          <v-container
            id="expandDispMine"
            grid-list-xs
          >
            <v-row>
              <v-col
                cols="12"
                class="pt-0"
              >
                <v-radio-group
                  v-model="radioGroup"
                  class="mt-0 pt-0"
                  mandatory
                >
                  <v-expansion-panels
                    accordion
                    multiple
                  >
                    <v-expansion-panel style="border: none;">
                      <div
                        class="d-flex justify-space-between align-center px-4"
                        style="background-color: aliceblue;"
                      >
                        <v-row>
                          <v-col
                            cols="1"
                            class="d-flex justify-center align-center"
                          >
                            <v-radio
                              value="radio-2"
                              label=""
                              class="mb-0 d-flex justify-center align-center"
                            >
                            </v-radio>
                          </v-col>
                          <v-col cols="2">
                            <p class="mb-0 pr-2 pt-4 pb-0">
                              <b>23:40</b> — 09:15
                            </p>
                            <p class="mb-0 pr-2 pb-4 pt-0">
                              Duración del viaje 17 h 35 min.
                            </p>
                          </v-col>
                          <v-col cols="2">
                            <p class="mb-0 pr-2 pt-4 pb-0">
                              1 escala
                            </p>
                            <p class="mb-0 pr-2 pb-4 pt-0">
                              4 h 15 min., Ciudad de México, desde 04:00
                            </p>
                          </v-col>
                          <v-col cols="1">
                            <p class="mb-0 pr-2 pt-4 pb-0">
                              AM-22
                            </p>
                            <p class="mb-0 pr-2 pb-4 pt-0">
                              AM-672
                            </p>
                          </v-col>
                          <v-col cols="3">
                            <p class="mb-0 pr-2 pt-4 pb-0">
                              Boeing 787-900
                            </p>
                            <p class="mb-0 pr-2 pb-4 pt-0">
                              Boeing 737-800 Winglets
                            </p>
                          </v-col>
                        </v-row>
                        <v-expansion-panel-header
                          ripple
                          style="background-color: aliceblue; width:40px"
                          class="d-flex justify-center align-center"
                        >
                        </v-expansion-panel-header>
                      </div>
                      <v-expansion-panel-content style="background-color: aliceblue;">
                        <v-container
                          fluid
                          class="ma-2"
                          style="background-color: white;"
                        >
                          <v-row
                            dense
                            class="pt-0"
                          >
                            <v-col cols="12">
                              <v-timeline
                                align-top
                                dense
                                class="pt-0"
                              >
                                <v-timeline-item
                                  color="primary"
                                  small
                                >
                                  <v-row class="pt-1">
                                    <v-col cols="2">
                                      <strong>23:40</strong><br />
                                      <light>10 jun. 2022</light>
                                    </v-col>
                                    <v-col cols="10">
                                      <strong class="pr-2">MAD</strong><br>
                                      <light>Adolfo Suarez Madrid-Barajas Airport, Terminal 1</light><br>
                                      <div class="text-caption">
                                        Madrid
                                      </div>
                                    </v-col>
                                    <v-col cols="12">
                                      <light>Duración del viaje 17 h 35 min.</light><br />
                                    </v-col>
                                  </v-row>
                                </v-timeline-item>

                                <v-timeline-item
                                  color="primary"
                                  small
                                >
                                  <v-row class="pt-1">
                                    <v-col cols="2">
                                      <strong>04:00</strong><br />
                                      <light>11 jun. 2022</light>
                                    </v-col>
                                    <v-col cols="10">
                                      <strong class="pr-2">MAD</strong><br>
                                      <light>Adolfo Suarez Madrid-Barajas Airport, Terminal 1</light><br>
                                      <div class="text-caption">
                                        Madrid
                                      </div>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col
                                      cols="12"
                                      class="pt-4 pl-4 pb-0"
                                    >
                                      <p class="mb-0 pr-2">
                                        Vuelo AM-22・Boeing 787-900
                                      </p>
                                      <p class="mb-0 pr-2">
                                        Economy class (L)・Tarifa CLASICA (LNNNNICI)
                                      </p>
                                    </v-col>
                                    <v-col
                                      cols="12"
                                      class="pt-0"
                                    >
                                      <a href="#">
                                        <p class="mb-0 pr-2">Reglas de la tarifa</p>
                                      </a>
                                    </v-col>
                                  </v-row>
                                </v-timeline-item>
                              </v-timeline>
                            </v-col>
                          </v-row>
                        </v-container>
                        <v-container
                          fluid
                          class="ma-2"
                        >
                          <p class="mb-0">
                            <b>4 h 15 min.Escala: Ciudad de México, desde 04:00 Escala larga</b>
                          </p>
                        </v-container>
                        <v-container
                          fluid
                          class="ma-2"
                          style="background-color: white;"
                        >
                          <v-row
                            dense
                            class="pt-0"
                          >
                            <v-col cols="12">
                              <v-timeline
                                align-top
                                dense
                                class="pt-0"
                              >
                                <v-timeline-item
                                  color="primary"
                                  small
                                >
                                  <v-row class="pt-1">
                                    <v-col cols="2">
                                      <strong>23:40</strong><br />
                                      <light>10 jun. 2022</light>
                                    </v-col>
                                    <v-col cols="10">
                                      <strong class="pr-2">MAD</strong><br>
                                      <light>Adolfo Suarez Madrid-Barajas Airport, Terminal 1</light><br>
                                      <div class="text-caption">
                                        Madrid
                                      </div>
                                    </v-col>
                                    <v-col cols="12">
                                      <light>Duración del viaje 17 h 35 min.</light><br />
                                    </v-col>
                                  </v-row>
                                </v-timeline-item>

                                <v-timeline-item
                                  color="primary"
                                  small
                                >
                                  <v-row class="pt-1">
                                    <v-col cols="2">
                                      <strong>04:00</strong><br />
                                      <light>11 jun. 2022</light>
                                    </v-col>
                                    <v-col cols="10">
                                      <strong class="pr-2">MAD</strong><br>
                                      <light>Adolfo Suarez Madrid-Barajas Airport, Terminal 1</light><br>
                                      <div class="text-caption">
                                        Madrid
                                      </div>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col
                                      cols="12"
                                      class="pt-4 pl-4 pb-0"
                                    >
                                      <p class="mb-0 pr-2">
                                        Vuelo AM-22・Boeing 787-900
                                      </p>
                                      <p class="mb-0 pr-2">
                                        Economy class (L)・Tarifa CLASICA (LNNNNICI)
                                      </p>
                                    </v-col>
                                    <v-col
                                      cols="12"
                                      class="pt-0"
                                    >
                                      <a href="#">
                                        <p class="mb-0 pr-2">Reglas de la tarifa</p>
                                      </a>
                                    </v-col>
                                  </v-row>
                                </v-timeline-item>
                              </v-timeline>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-container>
          <v-divider></v-divider>
          <v-container
            id="fromToHeadMine"
            grid-list-xs
          >
            <v-row>
              <v-col cols="12">
                <div class="d-flex justify-space-between align-center">
                  <div class="d-block justify-center">
                    <div class="d-flex justify-start align-center">
                      <h2>Madrid</h2>
                      <v-icon
                        class="px-2"
                        color="primary"
                      >
                        {{ icons.mdiSwapHorizontal }}
                      </v-icon>
                      <h2>Nueva York</h2>
                      <h2 class="px-2">
                        .
                      </h2>
                      <p class="mb-0 mt-2">
                        10 jun., 2022, vie.
                      </p>
                    </div>
                    <div class="d-flex justify-start align-center">
                      <p class="mb-0 pr-2">
                        Adolfo Suarez Madrid-Barajas Airport
                      </p>
                      <h4 class="mb-0 pr-2">
                        MAD
                      </h4>
                      <h4 class="mb-0 pr-2">
                        →
                      </h4>
                      <p class="mb-0 pr-2">
                        La Aurora International Airport
                      </p>
                      <h4 class="mb-0">
                        GUA
                      </h4>
                    </div>
                  </div>
                  <div>
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          x-large
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ icons.mdiPlus }}
                        </v-icon>
                      </template>
                      <span>Tooltip</span>
                    </v-tooltip>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
          <v-container
            id="expandDispMine"
            grid-list-xs
          >
            <v-row>
              <v-col
                cols="12"
                class="pt-0"
              >
                <v-radio-group
                  v-model="radioGroup"
                  class="mt-0 pt-0"
                  mandatory
                >
                  <v-expansion-panels
                    accordion
                    multiple
                  >
                    <v-expansion-panel style="border: none;">
                      <div
                        class="d-flex justify-space-between align-center px-4"
                        style="background-color: aliceblue;"
                      >
                        <v-row>
                          <v-col
                            cols="1"
                            class="d-flex justify-center align-center"
                          >
                            <v-radio
                              value="radio-2"
                              label=""
                              class="mb-0 d-flex justify-center align-center"
                            >
                            </v-radio>
                          </v-col>
                          <v-col cols="2">
                            <p class="mb-0 pr-2 pt-4 pb-0">
                              <b>23:40</b> — 09:15
                            </p>
                            <p class="mb-0 pr-2 pb-4 pt-0">
                              Duración del viaje 17 h 35 min.
                            </p>
                          </v-col>
                          <v-col cols="2">
                            <p class="mb-0 pr-2 pt-4 pb-0">
                              1 escala
                            </p>
                            <p class="mb-0 pr-2 pb-4 pt-0">
                              4 h 15 min., Ciudad de México, desde 04:00
                            </p>
                          </v-col>
                          <v-col cols="1">
                            <p class="mb-0 pr-2 pt-4 pb-0">
                              AM-22
                            </p>
                            <p class="mb-0 pr-2 pb-4 pt-0">
                              AM-672
                            </p>
                          </v-col>
                          <v-col cols="3">
                            <p class="mb-0 pr-2 pt-4 pb-0">
                              Boeing 787-900
                            </p>
                            <p class="mb-0 pr-2 pb-4 pt-0">
                              Boeing 737-800 Winglets
                            </p>
                          </v-col>
                        </v-row>
                        <v-expansion-panel-header
                          ripple
                          style="background-color: aliceblue; width:40px"
                          class="d-flex justify-center align-center"
                        >
                        </v-expansion-panel-header>
                      </div>
                      <v-expansion-panel-content style="background-color: aliceblue;">
                        <v-container
                          fluid
                          class="ma-2"
                          style="background-color: white;"
                        >
                          <v-row
                            dense
                            class="pt-0"
                          >
                            <v-col cols="12">
                              <v-timeline
                                align-top
                                dense
                                class="pt-0"
                              >
                                <v-timeline-item
                                  color="primary"
                                  small
                                >
                                  <v-row class="pt-1">
                                    <v-col cols="2">
                                      <strong>23:40</strong><br />
                                      <light>10 jun. 2022</light>
                                    </v-col>
                                    <v-col cols="10">
                                      <strong class="pr-2">MAD</strong><br>
                                      <light>Adolfo Suarez Madrid-Barajas Airport, Terminal 1</light><br>
                                      <div class="text-caption">
                                        Madrid
                                      </div>
                                    </v-col>
                                    <v-col cols="12">
                                      <light>Duración del viaje 17 h 35 min.</light><br />
                                    </v-col>
                                  </v-row>
                                </v-timeline-item>

                                <v-timeline-item
                                  color="primary"
                                  small
                                >
                                  <v-row class="pt-1">
                                    <v-col cols="2">
                                      <strong>04:00</strong><br />
                                      <light>11 jun. 2022</light>
                                    </v-col>
                                    <v-col cols="10">
                                      <strong class="pr-2">MAD</strong><br>
                                      <light>Adolfo Suarez Madrid-Barajas Airport, Terminal 1</light><br>
                                      <div class="text-caption">
                                        Madrid
                                      </div>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col
                                      cols="12"
                                      class="pt-4 pl-4 pb-0"
                                    >
                                      <p class="mb-0 pr-2">
                                        Vuelo AM-22・Boeing 787-900
                                      </p>
                                      <p class="mb-0 pr-2">
                                        Economy class (L)・Tarifa CLASICA (LNNNNICI)
                                      </p>
                                    </v-col>
                                    <v-col
                                      cols="12"
                                      class="pt-0"
                                    >
                                      <a href="#">
                                        <p class="mb-0 pr-2">Reglas de la tarifa</p>
                                      </a>
                                    </v-col>
                                  </v-row>
                                </v-timeline-item>
                              </v-timeline>
                            </v-col>
                          </v-row>
                        </v-container>
                        <v-container
                          fluid
                          class="ma-2"
                        >
                          <p class="mb-0">
                            <b>4 h 15 min.Escala: Ciudad de México, desde 04:00 Escala larga</b>
                          </p>
                        </v-container>
                        <v-container
                          fluid
                          class="ma-2"
                          style="background-color: white;"
                        >
                          <v-row
                            dense
                            class="pt-0"
                          >
                            <v-col cols="12">
                              <v-timeline
                                align-top
                                dense
                                class="pt-0"
                              >
                                <v-timeline-item
                                  color="primary"
                                  small
                                >
                                  <v-row class="pt-1">
                                    <v-col cols="2">
                                      <strong>23:40</strong><br />
                                      <light>10 jun. 2022</light>
                                    </v-col>
                                    <v-col cols="10">
                                      <strong class="pr-2">MAD</strong><br>
                                      <light>Adolfo Suarez Madrid-Barajas Airport, Terminal 1</light><br>
                                      <div class="text-caption">
                                        Madrid
                                      </div>
                                    </v-col>
                                    <v-col cols="12">
                                      <light>Duración del viaje 17 h 35 min.</light><br />
                                    </v-col>
                                  </v-row>
                                </v-timeline-item>

                                <v-timeline-item
                                  color="primary"
                                  small
                                >
                                  <v-row class="pt-1">
                                    <v-col cols="2">
                                      <strong>04:00</strong><br />
                                      <light>11 jun. 2022</light>
                                    </v-col>
                                    <v-col cols="10">
                                      <strong class="pr-2">MAD</strong><br>
                                      <light>Adolfo Suarez Madrid-Barajas Airport, Terminal 1</light><br>
                                      <div class="text-caption">
                                        Madrid
                                      </div>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col
                                      cols="12"
                                      class="pt-4 pl-4 pb-0"
                                    >
                                      <p class="mb-0 pr-2">
                                        Vuelo AM-22・Boeing 787-900
                                      </p>
                                      <p class="mb-0 pr-2">
                                        Economy class (L)・Tarifa CLASICA (LNNNNICI)
                                      </p>
                                    </v-col>
                                    <v-col
                                      cols="12"
                                      class="pt-0"
                                    >
                                      <a href="#">
                                        <p class="mb-0 pr-2">Reglas de la tarifa</p>
                                      </a>
                                    </v-col>
                                  </v-row>
                                </v-timeline-item>
                              </v-timeline>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-container>

          <v-divider class="px-0 py-3"></v-divider>
          <v-container
            id=""
            grid-list-xs
          >
            <v-row>
              <v-col
                cols="12"
                md="8"
                class="pt-0"
              >
                <v-card
                  elevation="0"
                  style="border: 1px solid #e3e1e4;"
                >
                  <v-container grid-list-xs>
                    <v-row>
                      <v-col cols="9">
                        <div class="d-block">
                          <p class="mb-0 d-flex justify-start">
                            <b>Nombre de la Tarifa</b>
                          </p>
                          <p class="mb-0 d-flex justify-start">
                            Clase: <b class="pl-2">Nombre de la Clase</b>
                          </p>
                        </div>
                        <v-row class="pt-4">
                          <v-col cols="1">
                            <v-icon>{{ icons.mdiPlus }}</v-icon>
                          </v-col>
                          <v-col
                            cols="4"
                            class="px-0"
                          >
                            <p
                              caption
                              class="p14Mine mb-0 d-flex justify-center align-baseline"
                            >
                              Consulta las reglas de la tarifa para saber si se admiten cambios.
                            </p>
                          </v-col>
                          <v-col cols="7">
                            <div class="d-flex justify-start align-center">
                              <v-icon style="color: #53af24;">
                                {{ icons.mdiBagSuitcase }}
                              </v-icon>
                              <p
                                style="color: #53af24;"
                                class="p14Mine mb-0 d-flex justify-center align-baseline"
                              >
                                Equipaje: 1 pieza
                              </p>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row class="pt-0">
                          <v-col
                            cols="1"
                            class="pt-0"
                          >
                            <v-icon>{{ icons.mdiPlus }}</v-icon>
                          </v-col>
                          <v-col
                            cols="12"
                            md="4"
                            class="px-0 pt-0"
                          >
                            <p class="p14Mine mb-0 d-flex justify-center align-baseline">
                              Consulta las reglas de la tarifa para conocer la política de reembolso.
                            </p>
                          </v-col>
                          <v-col
                            cols="12"
                            md="7"
                            class="pt-0"
                          >
                            <div class="d-flex justify-start align-center">
                              <v-icon style="color: #53af24;">
                                {{ icons.mdiBagPersonal }}
                              </v-icon>
                              <p
                                style="color: #53af24;"
                                class="p14Mine mb-0 d-flex justify-center align-baseline"
                              >
                                Equipaje de mano: incluido
                              </p>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col
                        cols="12"
                        md="3"
                      >
                        <div class="d-block">
                          <p class="mb-0 pr-6 d-flex justify-center align-center">
                            817,76 $
                          </p>
                          <div class="d-flex justify-center">
                            <p class="mb-0 pr-1 d-flex justify-center align-center">
                              <b>776,87 $</b>
                            </p>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ icons.mdiInformation }}
                                </v-icon>
                              </template>
                              <span>
                                <div class="d-block">
                                  <div class="d-flex justify-start align-center">
                                    <p class="mb-1 mt-2"><b>Detalles del precio</b></p>
                                  </div>
                                  <div class="d-flex justify-space-between align-center">
                                    <p class="mb-0 pr-6">Tarifa</p>
                                    <p class="mb-0">776,87 $</p>
                                  </div>
                                  <div class="d-flex justify-space-between align-center">
                                    <p
                                      class="mb-0 pr-6"
                                      subtitle-2
                                    >Impuestos y tasas</p>
                                    <p
                                      class="mb-0"
                                      subtitle-2
                                    >776,87 $</p>
                                  </div>
                                  <v-divider
                                    color="white"
                                    class="mt-2"
                                  ></v-divider>
                                  <div class="d-flex justify-space-between align-center">
                                    <p class="my-2"><b>Total</b></p>
                                    <p
                                      class="my-2"
                                      subtitle-2
                                    >776,87 $</p>
                                  </div>
                                  <div
                                    style="background-color: #ffffff6b"
                                    class="mb-4 pa-2"
                                  >
                                    <div class="d-flex justify-space-between align-center">
                                      <p class="mb-0">Tu comisión</p>
                                      <p
                                        class="mb-0"
                                        subtitle-2
                                      >776,87 $</p>
                                    </div>
                                    <div class="d-flex justify-space-between align-center">
                                      <p class="mb-0"><b>PVP</b></p>
                                      <p
                                        class="mb-0"
                                        subtitle-2
                                      >776,87 $</p>
                                    </div>
                                  </div>
                                </div>
                              </span>
                            </v-tooltip>
                          </div>
                          <a href="#">
                            <p
                              style="position: absolute; bottom:15px"
                              class="p14Mine mb-0 d-flex justify-center align-center"
                            >
                              Reglas de la tarifa
                            </p>
                          </a>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                md="4"
                class="pt-0"
              >
                <v-btn
                  color="primary"
                  width="100%"
                  class="mb-2"
                >
                  Enviar
                </v-btn>
                <v-btn
                  color="#e3e1e4"
                  width="100%"
                >
                  Otras tarifas con equipaje<v-icon>{{ icons.mdiChevronDown }} </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
            </v-row>
          </v-container>
        </v-card>-->
      </v-col>
    </v-row>
    <NoResults v-else />
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiCar,
  mdiBed,
  mdiSeatPassenger,
  mdiWalletTravel,
  mdiAirplaneTakeoff,
  mdiAirplane,
  mdiAirplaneLanding,
  mdiSwapHorizontal,
  mdiOfficeBuilding,
  mdiEye,
  mdiPencil,
  mdiDelete,
  mdiPlusThick,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
  mdiChevronDown,
  mdiBagPersonal,
  mdiBagSuitcase,
  mdiInformation,
  mdiHeart,
  mdiHeartBroken,
} from '@mdi/js'

// eslint-disable-next-line import/no-unresolved
import IdaAerolineaDestinos from './utils/IdaAerolineaDestinos.vue'
import NoResults from '../../utils/NoResults.vue'

export default {
  components: {
    IdaAerolineaDestinos,
    NoResults,
  },
  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoadingTarifaEcon: false,
      radioGroup: 0,
      items: [],
      indexTarifa: 0,
      showOtherTarifas: false,
      rowShowOtherTarifas: -1,
      icons: {
        mdiCar,
        mdiBed,
        mdiSeatPassenger,
        mdiWalletTravel,
        mdiAirplaneTakeoff,
        mdiAirplane,
        mdiAirplaneLanding,
        mdiSwapHorizontal,
        mdiOfficeBuilding,
        mdiEye,
        mdiPencil,
        mdiDelete,
        mdiPlusThick,
        mdiChevronRight,
        mdiPlus,
        mdiMinus,
        mdiChevronDown,
        mdiBagPersonal,
        mdiBagSuitcase,
        mdiInformation,
        mdiHeart,
        mdiHeartBroken,
      },
    }
  },
  computed: {
    ...mapState({
      cotizadorFlight: state => state.app.cotizadorFlight,
      destinosCotizadorFlight: state => state.app.destinosCotizadorFlight,
      aerolineCotizadorDestinosFlight: state => state.app.aerolineCotizadorDestinosFlight,
      cotizadorFlightFavorite: state => state.app.cotizadorFlightFavorite,
    }),
  },
  created() {
    // this.getDisponibilidadFlightIda()
  },
  methods: {
    ...mapMutations(['setTarifaEconCotizadorIdaFligth', 'clearCotizadorFlightFavorite', 'clearIdFlightFavorite']),
    clearFavorites() {
      this.clearCotizadorFlightFavorite()
      this.clearIdFlightFavorite()
    },
    getFavorite() {
      this.$router.push({ name: 'cotizador-favorite' })
    },
  },
}
</script>

<template>
  <v-card>
    <v-card-title
      id="titleDispVueloMine"
      class="primary"
    >
      <!--<v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <img
            :src="rutaPublic + aerolineCotizadorIdaFlight[indAero].aeroline.isotipo"
            :alt="aerolineCotizadorIdaFlight[indAero].aeroline.slug"
            height="25"
            color="white"
            v-bind="attrs"
            v-on="on"
          >
        </template>
        <span>{{ aerolineCotizadorIdaFlight[indAero].aeroline.name }}</span>
      </v-tooltip>-->
      <!--<div class="d-flex justify-start align-baseline px-2">
        <p class="mb-0 pr-2">
          {{ $t('lbl.aerolinea') }}:
        </p>
        <h5
          class="mb-0"
          style="font-weight: bold;"
        >
          {{ aerolineCotizadorIdaFlight[indAero].aeroline.name }}
        </h5>
      </div>-->
      <v-spacer></v-spacer>
      <div class="d-flex justify-start align-baseline px-2">
        <p class="mb-0 pr-2">
          Ticket Time Limit:
        </p>
        <h5 class="mb-0">
          50 minutos
        </h5>
      </div>
    </v-card-title>
    <v-container
      id="fromToHeadMine"
      grid-list-xs
    >
      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-space-between align-center">
            <div class="d-block justify-center">
              <p class="mb-0">
                {{ destinosCotizadorFlight[0].from | moment("MMMM D, YYYY, dddd") }}
              </p>
              <div class="d-flex justify-start align-center">
                <p class="mb-0 pr-2">
                  {{ destinosCotizadorFlight[0].origen.nombre }}
                </p>
                <h4 class="mb-0 pr-2">
                  {{ destinosCotizadorFlight[0].origen.iata }}
                </h4>
                <h4 class="mb-0 pr-2">
                  →
                </h4>
                <p class="mb-0 pr-2">
                  {{ destinosCotizadorFlight[0].destino.nombre }}
                </p>
                <h4 class="mb-0">
                  {{ destinosCotizadorFlight[0].destino.iata }}
                </h4>
              </div>
            </div>
            <div>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <!--<v-icon
                        x-large
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ icons.mdiPlus }}
                      </v-icon>-->
                  <img
                    :src="rutaPublic + aerolineCotizadorIdaFlight[indAero].aeroline.isotipo"
                    :alt="aerolineCotizadorIdaFlight[indAero].aeroline.slug"
                    height="40"
                    color="white"
                    v-bind="attrs"
                    v-on="on"
                  >
                </template>
                <span>{{ aerolineCotizadorIdaFlight[indAero].aeroline.name }}</span>
              </v-tooltip>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container
      id="expandDispMine"
      grid-list-xs
    >
      <v-row>
        <v-col
          cols="12"
          class="pt-0"
        >
          <v-radio-group
            v-model="radioGroup"
            class="mt-0 pt-0"
            mandatory
          >
            <v-expansion-panels
              accordion
              multiple
            >
              <IdaFlight
                v-for="(item, indexF) in aerolineCotizadorIdaFlight[indAero].flights"
                :key="indexF"
                :index="indexF"
                :ind-aero="indAero"
              />
            </v-expansion-panels>
          </v-radio-group>
        </v-col>
      </v-row>
    </v-container>

    <v-divider class="px-0 py-3"></v-divider>
    <!--TARIFA ECONOMICA-->
    <IdaTarifaEconomica
      :ind-aero="indAero"
      :index-tarifa="aerolineCotizadorIdaFlight[indAero].indexTarifa"
    />
  </v-card>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiCar,
  mdiBed,
  mdiSeatPassenger,
  mdiWalletTravel,
  mdiAirplaneTakeoff,
  mdiAirplane,
  mdiAirplaneLanding,
  mdiSwapHorizontal,
  mdiOfficeBuilding,
  mdiEye,
  mdiPencil,
  mdiDelete,
  mdiPlusThick,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
  mdiChevronDown,
  mdiBagPersonal,
  mdiBagSuitcase,
  mdiInformation,
} from '@mdi/js'

// eslint-disable-next-line import/no-unresolved
import IdaFlight from './IdaFlight.vue'
import IdaTarifaEconomica from './IdaTarifaEconomica.vue'

export default {
  components: {
    IdaFlight,
    IdaTarifaEconomica,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    indAero: {
      type: Number,
    },
  },
  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoadingTarifaEcon: false,
      radioGroup: 0,
      items: [],
      indexTarifa: 0,
      showOtherTarifas: false,
      rowShowOtherTarifas: -1,
      icons: {
        mdiCar,
        mdiBed,
        mdiSeatPassenger,
        mdiWalletTravel,
        mdiAirplaneTakeoff,
        mdiAirplane,
        mdiAirplaneLanding,
        mdiSwapHorizontal,
        mdiOfficeBuilding,
        mdiEye,
        mdiPencil,
        mdiDelete,
        mdiPlusThick,
        mdiChevronRight,
        mdiPlus,
        mdiMinus,
        mdiChevronDown,
        mdiBagPersonal,
        mdiBagSuitcase,
        mdiInformation,
      },
    }
  },
  computed: {
    ...mapState({
      cotizadorFlight: state => state.app.cotizadorFlight,
      destinosCotizadorFlight: state => state.app.destinosCotizadorFlight,
      resultCotizadorIdaFlight: state => state.app.resultCotizadorIdaFlight,
      tarifaEconCotizadorIdaFlight: state => state.app.tarifaEconCotizadorIdaFlight,
      aerolineCotizadorIdaFlight: state => state.app.aerolineCotizadorIdaFlight,
    }),
  },
  methods: {
    ...mapMutations(['setTarifaEconCotizadorIdaFligth']),
  },
}
</script>
